import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  scroll-margin-top: 8rem;
  background: #FDF7F3;
  padding-bottom: 4rem;
  @media (max-width: 40rem){
    background: none;
    scroll-margin-top: 3rem;
  }
`

const SectionName = styled.div`
  font-size: 3.25rem;
  text-align: center;
  font-family: 'Lusitana', serif;
  padding-top: 6rem;
`

const ImgboxOuter = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1.5rem;
    margin: 0 auto;
    padding: 5rem 2rem;
    max-width: 63rem;
    @media (max-width: 62.5rem){
      grid-template-columns: repeat(2,1fr);
      padding: 2rem;
    }
    @media (max-width: 40rem){
      gap: 0.5rem;
    }
`

const FeedItem = styled.a`
  display: block;
`

const FeedImageContainer = styled.div`
  img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
`


interface InstagramBusinessAccountResponse {
  name: string
  media: InstagramMediaList
  id: string
  error?: InstagramError
}

interface InstagramError {
  message: string,
  type: string,
  code: number,
  fbtrace_id: string
}

interface InstagramMediaList {
  data: InstagramMedia[]
  paging: InstagramPaging
}

interface InstagramMedia {
  media_url?: string
  permalink: string
  id: string
  caption?: string
  thumbnail_url?: string
  media_product_type: string
}

interface InstagramPaging {
  cursors: {
    before: string,
    after: string
  }
  next: string
}


type WorkSectionProps = {

}
export const WorkSection: FunctionComponent<WorkSectionProps> = () => {
  const [feeds, setFeeds] = useState<InstagramMedia[]>([])
  useEffect(() => {
    fetch('https://graph.facebook.com/v18.0/17841450512383421?fields=name%2Cmedia.limit(12)%7Bcaption%2Cmedia_url%2Cthumbnail_url%2Cpermalink%2Cmedia_product_type%7D&access_token=EAAOG8aPrs6QBO0b2BFIe09ZATQbxxjZApSTMKBFFwmVwIsZCmAutuQ37UZCGOtFZAaWtKr9B3zq6A8XqvYns0JI0NtsQ8ThX2fQfFDPh9vKz2mmFfMqY5d5KIM4FiAKHvBbqV1Aa3ahHlbVYhbEuHsmXbQwwsBAoZAzrTeofMeXdesKpEOwQvF0sc6').then(res => (res.json() as Promise<InstagramBusinessAccountResponse>)).then(res => {
      if(!res.error){
        setFeeds(res.media.data)
      }
    })
  }, [])
  return <Outer id='work-section'>
    <SectionName>Work</SectionName>
    <ImgboxOuter>
        {
          feeds.map(feed => {
            console.log(feed.media_product_type, feed.media_url, feed.thumbnail_url)
            return <FeedItem key={feed.id} href={feed.permalink}>
              <FeedImageContainer>
                <img src={feed.thumbnail_url ?? feed.media_url} />
              </FeedImageContainer>
            </FeedItem>
          })
        }
    </ImgboxOuter>
  </Outer>
}