import React, { FunctionComponent, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import SvgScroll from '../generated/svg/scroll'
import SvgLogo from '../generated/svg/logo'

const Outer = styled.div`
    font-family: 'Lusitana', serif;
    display: flex;
    justify-content: center;
    flex-flow: column;
    height: calc(100lvh - var(--header-height));
    margin-top: var(--header-height);
    position: relative;
`

const TextOuter = styled.div`
  margin-top: -8lvh;
`
const Logo = styled.div`
    svg {
        width: 8.4375rem;
        height: auto;
        display: none;
        @media (max-width: 41.875rem){
            display: block;
        }
    }
`

const TextItem = styled.div`
    font-size: 3.6875rem;
    display: flex;
    @media (max-width: 75rem){
      font-size: 2.9375rem;
    }
    @media (max-width: 53.125rem){
      flex-flow: column;
      gap: 1rem;
      margin: 2rem 0;
    }
`

const Text1 = styled.div`
    &:nth-child(-n+3)::after {
        content: "/";
        margin: 0 1.5rem;
        @media (max-width: 53.125rem){
          display: none;
        }
    }
    
`




const Text = styled.div`
    font-size: 2.3rem;
    color: #A7A7A7;
    margin-top: 1rem;
    line-height: 3.5rem;
    @media (max-width: 75rem){
      font-size: 2rem;
      line-height: 3rem;
    }
    @media (max-width: 40rem){
      font-size: 1.2rem;
      line-height: 1.9rem;
    }
`

const ScrollOuter = styled.div`
    margin: 2rem 0;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 40rem){
      display: none;
    }
    &:hover {
    color: #0092E5;
  }
`

const ScrollDown = styled.div`
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1rem;
    transition: all 0.4s;

`

const scroll = keyframes`
  0% {
    transform: translateY(-0.5rem);
  }
  100% {
    transform: translateY(0.5rem);
  }

`
const feed = keyframes`
  0% {
    opacity: 0;
  }
  40%,60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const ScrollIcon = styled.div`
  animation: ${scroll} 2s infinite linear,${feed} 2s infinite linear;
    svg {
        color:#707070;
        width: 2.125rem;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: all 0.4s;
        ${ScrollOuter}:hover &{color: #0092E5;}
    }
    
`
const Mbr = styled.br`
    display: none;
    @media (max-width: 40rem){
      display: inline;
    }
`

const Dbr = styled.br`
    @media (max-width: 43.75rem){
      display: none;
    }
`

type TopSectionProps = {

}
export const TopSection: FunctionComponent<TopSectionProps> = () => {
  const [margin, setMargin] = useState(0)
  const [headerHeight, setHeaderHeight] = useState(0)
  useEffect(() => {
    const listener = () => {
      const titleElement = document.querySelector('#title')
      if(titleElement){
        const rect = titleElement.getBoundingClientRect()
        setMargin(rect.left)
      }
      const headerElement = document.querySelector('#header')
      if(headerElement){
        const rect = headerElement.getBoundingClientRect()
        setHeaderHeight(rect.height)
      }
    }
    listener()
    window.addEventListener('resize', listener)
    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return <Outer id='top-section' style={{'--header-height': `${headerHeight}px`} as React.CSSProperties}>
    <TextOuter style={{marginLeft: `${margin}px`, marginRight: `${margin}px`}}>
        <Logo>
            <SvgLogo/>
        </Logo>
        <TextItem>
            <Text1>PR</Text1>
            <Text1>Branding</Text1>
            <Text1>SNS</Text1>
            <Text1>Media</Text1>
        </TextItem>
        <Text>We are a wellness focused <Mbr/>PR office <Dbr/>
with highly specialized <Mbr/>experts to grow your brand.</Text>
    </TextOuter>
    <ScrollOuter onClick={() => {
        document.getElementById("message-section")?.scrollIntoView({behavior:'smooth'})
    }}>
        <ScrollDown>SCROLL DOWN</ScrollDown>
        <ScrollIcon>
            <SvgScroll/>
        </ScrollIcon>
    </ScrollOuter>
  </Outer>
}