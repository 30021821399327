import * as React from "react";
import type { SVGProps } from "react";
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 102.806 86.541"
    {...props}
  >
    <defs>
      <clipPath id="uuid-db9fb331-412a-4b36-b99f-cd638524ec3f">
        <path fill="none" d="M0 0h102.806v86.541H0z" />
      </clipPath>
    </defs>
    <g data-name="\u30B0\u30EB\u30FC\u30D7 227">
      <g
        fill="currentcolor"
        clipPath="url(#uuid-db9fb331-412a-4b36-b99f-cd638524ec3f)"
        data-name="\u30B0\u30EB\u30FC\u30D7 215"
      >
        <path
          d="M102.783 84.406a20.1 20.1 0 0 1-7.914-2.034 28.137 28.137 0 0 1-7.386-5.891l-6.14-7.374c-.272-.368-.526-.69-.765-.966s-.515-.6-.832-.965l-26.78-34.414 5.265-5.262 1.515-1.45 6.69-6.409C76.448 10.086 85.381 4.205 93.236 2V0H55.13v2.137a12.763 12.763 0 0 1 7.512 2.894 8.14 8.14 0 0 1 2.826 6.409 11.314 11.314 0 0 1-1.965 6.06 47.123 47.123 0 0 1-7 7.924l-5.26 5.125-10.956 10.683 21.554 27.875a39.854 39.854 0 0 1 3.857 5.477 10.672 10.672 0 0 1 1.261 3.826c.233 2.067-.3 3.537-1.6 4.411-1.3.873-3.532 1.4-7.14 1.585l.015 2.136h44.571l-.022-2.136Z"
          data-name="\u30D1\u30B9 351"
        />
        <path
          d="M32.044 78.683a11.636 11.636 0 0 1-2-4.789 43.705 43.705 0 0 1-.62-8.373v-44.5a43.69 43.69 0 0 1 .62-8.373 11.636 11.636 0 0 1 2-4.789c2.298-2.987 5.928-4.894 10.889-5.721V0H0v2.136c4.501.735 7.901 2.39 10.2 4.964a10.649 10.649 0 0 1 2.55 4.824c.555 3 .786 6.05.689 9.1v44.5a42.58 42.58 0 0 1-.689 9.1 10.649 10.649 0 0 1-2.55 4.824C7.901 82.016 4.501 83.667 0 84.4v2.14h42.933V84.4c-4.962-.827-8.592-2.734-10.889-5.721"
          data-name="\u30D1\u30B9 352"
        />
      </g>
    </g>
  </svg>
);
export default SvgLogo;
