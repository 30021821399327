import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useExternalCss } from '../hooks/use-external-css';

const Css = createGlobalStyle`
  :root{
    font-family: 'Lusitana', serif;
  }
  body {
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
`

export const RootCss: FunctionComponent = () => {
  useExternalCss('https://fonts.googleapis.com/css2?family=Lusitana&display=swap')
  return <Css />
}