import * as React from "react";
import type { SVGProps } from "react";
const SvgInstaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 23.715 23.715"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M11.863 2.137c3.165 0 3.54.012 4.792.068.751.01 1.495.147 2.2.408a3.913 3.913 0 0 1 2.25 2.251c.262.704.4 1.449.408 2.2.059 1.25.069 1.625.069 4.792s-.011 3.541-.069 4.792a6.513 6.513 0 0 1-.408 2.2 3.915 3.915 0 0 1-2.255 2.25 6.564 6.564 0 0 1-2.2.408c-1.252.058-1.626.069-4.792.069s-3.541-.012-4.793-.069a6.564 6.564 0 0 1-2.2-.408 3.917 3.917 0 0 1-2.25-2.25 6.456 6.456 0 0 1-.408-2.2c-.059-1.251-.069-1.626-.069-4.792s.011-3.542.069-4.792a6.456 6.456 0 0 1 .408-2.2 3.913 3.913 0 0 1 2.251-2.251 6.564 6.564 0 0 1 2.2-.408c1.252-.056 1.626-.068 4.793-.068m0-2.137c-3.222 0-3.624.014-4.89.072a8.717 8.717 0 0 0-2.879.55A6.07 6.07 0 0 0 .622 4.09a8.72 8.72 0 0 0-.551 2.878C.015 8.233 0 8.637 0 11.857s.014 3.624.071 4.888a8.73 8.73 0 0 0 .551 2.879 6.077 6.077 0 0 0 3.468 3.468 8.72 8.72 0 0 0 2.878.551c1.266.058 1.669.072 4.89.072s3.623-.014 4.889-.072a8.72 8.72 0 0 0 2.878-.551 6.077 6.077 0 0 0 3.468-3.468 8.73 8.73 0 0 0 .551-2.879c.056-1.264.071-1.667.071-4.888s-.014-3.624-.071-4.889a8.746 8.746 0 0 0-.551-2.878A6.07 6.07 0 0 0 19.626.622a8.719 8.719 0 0 0-2.878-.55C15.487.014 15.085 0 11.863 0"
      data-name="\u30D1\u30B9 939"
    />
    <path
      fill="currentcolor"
      d="M11.864 5.766a6.089 6.089 0 1 0 6.088 6.091 6.089 6.089 0 0 0-6.087-6.091h-.001m0 10.042a3.953 3.953 0 1 1 3.951-3.955v.001a3.953 3.953 0 0 1-3.951 3.953"
      data-name="\u30D1\u30B9 940"
    />
    <path
      fill="currentcolor"
      d="M19.615 5.529a1.423 1.423 0 1 1 0-.002v.002"
      data-name="\u30D1\u30B9 941"
    />
  </svg>
);
export default SvgInstaIcon;
