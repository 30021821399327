import React from 'react';
import { FunctionComponent } from 'react';
import { ResetCss } from './components/reset-css';
import { RootCss } from './components/root-css';
import { TopPage } from './pages/top-page';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { ScrollToTop } from './components/scroll-to-top';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ContactPage } from './pages/contact-page';
import { RestoreScroll } from './components/restore-scroll';
import { Scroll } from './components/scroll';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { StyleSheetManager } from 'styled-components';

const apolloClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://kurita-pr.com/wp/index.php?graphql',
    fetchOptions: {
      mode: 'cors'
    }
  }),
  cache: new InMemoryCache()
})

export const App: FunctionComponent = () => {
  return <>
    <StyleSheetManager enableVendorPrefixes>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter>
          <ResetCss />
          <RootCss />
          <Header />
          <RestoreScroll />
          <Scroll />
          <ScrollToTop/>
          <Routes>
            <Route index element={<TopPage />} />
            <Route path='contact' element={<ContactPage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ApolloProvider>
    </StyleSheetManager>
  </>
}