import * as React from "react";
import type { SVGProps } from "react";
const SvgPagetop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 8.065 82.152"
    {...props}
  >
    <path
      fill="none"
      stroke="currentcolor"
      d="M7.565 82.152V1.207L.354 8.418"
      data-name="\u30D1\u30B9 16"
    />
  </svg>
);
export default SvgPagetop;
