import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
    text-align: center;
    margin: 3rem;
    scroll-margin-top: 14rem;
    height: calc(100lvh - 6rem);
    @media (max-width: 40rem){
      font-family: 'Lusitana', serif;
      scroll-margin-top: 9rem;
    }
`

const SectionName = styled.div`
    font-family: 'Lusitana', serif;
    font-size: 3.25rem;
    margin-top: 10rem;
    @media (max-width: 40rem){
      margin-top: 3rem;
    }
`

const Text1 = styled.div`
    font-family: 'Lusitana', serif;
    font-size: 0.5625rem;
    color: #707070;
    @media (max-width: 41.875rem){
      margin-top: 1rem;
    }
`

const Text2 = styled.div`
    font-family: 'Lusitana', serif;
    font-size: 1.25rem;
    color: #707070;
    line-height: 2.6rem;
    margin: 20lvh 0 0;
    @media (max-width: 40rem){
      margin-bottom: 10rem;
    }
`
const Mbr = styled.br`
    display: none;
    @media (max-width: 40rem){
      display: inline;
    }
`

type ClientsSectionProps = {

}
export const ClientsSection: FunctionComponent<ClientsSectionProps> = () => {
  return <Outer id='clients-section'>
    <SectionName>Clients</SectionName>
    <Text1>※過去実績含む</Text1>
    <Text2>chant a charm / WELEDA / m.m.m /<Mbr/> E3Live / CUMEC<br/>
PURE SYNERGY / Trilogy / GINZA MUTSUMI / PRISTINE / SHIKI TOKYO/ ORIFUSI</Text2>
  </Outer>
}