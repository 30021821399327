import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { TopSection } from '../sections/top-section'
import { MessageSection } from '../sections/message-section'
import { ServiceSection } from '../sections/service-section'
import { WorkSection } from '../sections/work-section'
import { ClientsSection } from '../sections/clients-section'

const Outer = styled.div``

type TopPageProps = {

}
export const TopPage: FunctionComponent<TopPageProps> = () => {
  return <Outer>
    <TopSection></TopSection>
    <MessageSection></MessageSection>
    <ServiceSection></ServiceSection>
    <WorkSection></WorkSection>
    <ClientsSection></ClientsSection>
  </Outer>
}