import * as React from "react";
import type { SVGProps } from "react";
const SvgScroll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 34.635 34.989"
    {...props}
  >
    <g
      fill="none"
      stroke="currentcolor"
      data-name="\u30B0\u30EB\u30FC\u30D7 363"
    >
      <path
        d="m.354.354 16.963 16.963 10.6-10.6 6.361-6.36"
        data-name="\u30D1\u30B9 466"
      />
      <path
        d="m.354 17.317 16.964 16.964 16.964-16.964"
        data-name="\u30D1\u30B9 467"
      />
    </g>
  </svg>
);
export default SvgScroll;
