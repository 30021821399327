import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import SvgLogo from '../generated/svg/logo'
import SvgFacebookIcon from '../generated/svg/facebook-icon'
import SvgInstaIcon from '../generated/svg/insta-icon'
import SvgMenu from '../generated/svg/menu'
import SvgDelete from '../generated/svg/delete'
import { Link } from 'react-router-dom'

const MenuOuter = styled.div`
  opacity: 0;
  pointer-events: none;
  position: fixed;
  width: 100vw;
  height: 100lvh;
  top: 0;
  left: 0;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: hsla(0,0%,100%,.65);
  transition: opacity 0.4s;
  &.open {
    opacity: 1;
    pointer-events: all;
  }
  @media (max-width: 60rem){
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #fffc;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 3rem 0 1rem;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: hsla(0,0%,100%,.65);
  z-index: 100;
  &:has(${MenuOuter}.open) {
    backdrop-filter: none;
  }
  @media (max-width: 41.875rem){
    grid-template-columns: 1fr auto;
    padding: 2rem 0 1rem;
  }
`

const PageBorder = styled.div`
  position: fixed;
  width: 100vw;
  height: 100dvh;
  border: 1rem #244F89 solid;
  box-sizing: border-box;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1000;
  transition: height 0.2s;
  @media (max-width: 40rem){
    border: 0.8rem #244F89 solid;
  }
`
const LabelOuter = styled(Link)`
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 41.875rem){
    justify-content: flex-start;
  }
`

const Label = styled.div`
  font-family: 'Lusitana', serif;
  font-size: 2.625rem;
  width: max-content;
  transition: all 0.4s;
  &:hover {
    color: #0092E5;
  }
  @media (max-width: 68.75rem){
    font-size: 2rem;
  }
  @media (max-width: 51.25rem){
    font-size: 1.5rem;
  }
  @media (max-width: 41.875rem){
    text-align: left;
    font-size: 1.1875rem;
    margin-left: 2rem;
  }
`

const Logo = styled(Link)`
  margin-left: 4.5rem;
  margin-right: 3rem;
  position: relative;
  z-index: 100;
  transition: all 0.4s;
  &:hover {
    color: #0092E5;
  }
  svg {
    width: 6.375rem;
    height: auto;
    display: block;
    @media (max-width: 68.75rem){
      width: 5rem;
    }
  }  
  @media (max-width: 68.75rem){
    margin-left: 3rem;
    margin-right: 2rem;
  }
  @media (max-width: 41.875rem){
    display: none;
  }
`

const Navigator = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 60rem){
    display: none;
  }
`

const NavigatorList = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 68.75rem){
    gap: 0.75rem;
  }
`

const NavigatorListItem = styled(Link)`
  display: block;
  font-family: 'Lusitana', serif;
  font-size: 1.125rem;
  color: #555555;
  transition: all 0.4s;
  &:hover {
    color: #0092E5;
  }
  @media (max-width: 68.75rem){
    font-size: 0.9375rem;
  }
`

const SnsList = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-left: 2rem;
  @media (max-width: 68.75rem){
    margin-left: 1rem;
  }
`

const SnsListItem = styled.a`
  display: block;
  color: #555555;
  transition: all 0.4s;
  &:hover {
    color: #0092E5;
  }
  svg {
    width: 1.5rem;
    height: auto;
    display: block;
  }
`
const MenuButton = styled.div`
  display: none;
  position: relative;
  z-index: 100;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    color: #0092E5;
  }
  svg {
    width: auto;
    height: 1.4rem;
    display: block;
  }
  @media (max-width: 60rem){
    display: block;
    color: #707070;
    margin-right: 2rem;
    margin-left: auto;
  }
`

const MenuList = styled.div`
    margin: 3rem 0 ;
 
  
`

const MenuItem = styled(Link)`
    display: block;
    font-size: 1.5625rem;
    color: #0A2054;
    font-family: 'Lusitana', serif;
    margin-bottom: 1.5rem;
    transition: all 0.4s;
  &:hover {
    color: #0092E5;
  }
`

const MenuSns = styled.div`
    display: flex;
    justify-content: center;
    gap: 1.5rem;
 

`

const  MenuSnsIcon= styled.a`
    display: block;
    width: 1.75rem;
    height: auto;
    color: #555555;
    transition: all 0.4s;
  &:hover {
    color: #0092E5;
  }
`

type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [open, setOpen] = useState(false)
  return <Outer id="header">
  <PageBorder></PageBorder>
  <LabelOuter to='/'>
    <Label id="title">KURITA PR OFFICE</Label>
  </LabelOuter>
  <Logo to='/'>
    <SvgLogo/>
  </Logo>
  <Navigator>
    <NavigatorList>
      <NavigatorListItem to='/#message-section'>Message</NavigatorListItem>
      <NavigatorListItem to='/#service-section'>Service</NavigatorListItem>
      <NavigatorListItem to='/#work-section'>Work</NavigatorListItem>
      <NavigatorListItem to='/#clients-section'>Clients</NavigatorListItem>
      <NavigatorListItem to='/contact'>Contact</NavigatorListItem>
    </NavigatorList>
    <SnsList>
      <SnsListItem href='https://www.instagram.com/ayano_kurita_works/'>
        <SvgInstaIcon/>
      </SnsListItem>
    </SnsList>
  </Navigator>
  <MenuButton onClick={() => setOpen(!open)}>
    {
      open ? <SvgDelete/> : <SvgMenu/>
    }
    
  </MenuButton>
  <MenuOuter className={open ? 'open' : ''}>
    <MenuList>
      <MenuItem to='/#message-section' onClick={() => setOpen(false)}>Message</MenuItem>
      <MenuItem to='/#service-section' onClick={() => setOpen(false)}>Service</MenuItem>
      <MenuItem to='/#work-section' onClick={() => setOpen(false)}>Work</MenuItem>
      <MenuItem to='/#clients-section' onClick={() => setOpen(false)}>Clients</MenuItem>
      <MenuItem to='/contact' onClick={() => setOpen(false)}>Contact</MenuItem>
    </MenuList>
    <MenuSns>
      <MenuSnsIcon href='https://www.instagram.com/ayano_kurita_works/'>
        <SvgInstaIcon/>
      </MenuSnsIcon>
    </MenuSns>
  </MenuOuter>
  </Outer>
}