import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgScroll from '../generated/svg/scroll'
import SvgPagetop from '../generated/svg/pagetop'

const Outer = styled.div`
    position: fixed;
    left: 4.5rem;
    bottom: 1.75rem;
    cursor: pointer;
    transition: all 0.4s;
    &:hover {
            color: #0092E5;
     }
    @media (max-width: 40rem){
      display: none;
    }
`

const Img = styled.div`
    width: 0.4375rem;
    height: auto;
`

const Text = styled.div`
    font-size: 0.5625rem;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-family: 'Lusitana', serif;
`
type ScrollToTopProps = {

}
export const ScrollToTop: FunctionComponent<ScrollToTopProps> = () => {
  return <Outer onClick={() => {window.scrollTo({top: 0,behavior: "smooth",})}}>
    <Img>
        <SvgPagetop/>
    </Img>
    <Text>PAGE TOP</Text>
  </Outer>
}