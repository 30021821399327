import * as React from "react";
import type { SVGProps } from "react";
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 28.342 22"
    {...props}
  >
    <g
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeWidth={2}
      data-name="\u30B0\u30EB\u30FC\u30D7 246"
    >
      <path d="M27.342 1H1" data-name="\u30D1\u30B9 588" />
      <path d="M27.342 11H1" data-name="\u30D1\u30B9 590" />
      <path d="M27.342 21H1" data-name="\u30D1\u30B9 591" />
    </g>
  </svg>
);
export default SvgMenu;
