import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  margin-top: 14rem;
  @media (max-width: 50rem){
    font-family: 'Lusitana', serif;
    margin-top: 9rem;
  }
`

const InputOuter = styled.div`
  text-align: left;
  max-width: 41.125rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  @media (max-width: 50rem){
    max-width: 22.125rem;
  }
`

const Contact = styled.div`
  font-family: 'Lusitana', serif;
  font-size: 3.25rem;
  text-align: center;
  margin-bottom: 8rem;
`

const CompanyName = styled.div`
  font-size: 0.9375rem;
  margin: 0.5rem 0;
`

const Input = styled.input`
  width: 100%;
  height: 2.875rem;
  border: 0.0625rem solid #244F89;
  border-radius: 0.3125rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1rem;
`
const Textarea = styled(Input)`
  height: 10rem;
  box-sizing: border-box;
  padding: 1rem;
  border: 0.0625rem solid #244F89;
  font-size: 1rem;
`

const Name = styled.div`
  font-size: 0.9375rem;
  margin: 0.5rem 0;
`

const Email = styled.div`
  font-size: 0.9375rem;
  margin: 0.5rem 0;
`

const Tel = styled.div`
  font-size: 0.9375rem;
  margin: 0.5rem 0;
`

const Content = styled.div`
  font-size: 0.9375rem;
  margin: 0.5rem 0 1rem;
`
const Radio = styled.input`
`

const RadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem 1rem;

  @media (max-width: 50rem){
    flex-direction: column;
  }
`

const Label = styled.label`
  display: inline;
  margin: 0.7rem 0;
  display: block;

  @media (max-width: 50rem){
    display: block;
    margin: 0.7rem 0;
  }
`

const Message = styled.div`
  font-size: 0.9375rem;
  margin: 2rem 0 0.5rem;
`

const Text = styled.div`
  margin: 1rem 0 2rem;
  line-height: 1.5rem;
  font-size: 0.8rem;
`

const SendButton = styled.button`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.875rem;
  border-radius: 3.125rem;
  color: #fff;
  background-color: #244F89;
  border: none;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.4s;
  &:hover {
    background-color: #fff;
    color: #244F89;
    border: 0.0625rem solid #244F89;
  }
  @media (max-width: 50rem){
    max-width: 20.4rem;
  }
  
`

const SentMessage = styled.div`
  text-align: center;
  line-height: 1.75;
`

const objectToURLSearchFormData = (data: Record<string, string>) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => formData.append(key, value))
  return formData
}

type ContactPageProps = {

}
export const ContactPage: FunctionComponent<ContactPageProps> = () => {
  const [companyName, setCompanyName] = useState('')
  const [name, setName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [type, setType] = useState('')
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)

  const send = async () => {
    setSending(true)
    try {
      const res = await fetch('https://kurita-pr.com/wp/index.php?rest_route=/contact-form-7/v1/contact-forms/8/feedback', {
        method: 'POST',
        body: objectToURLSearchFormData({
          'company-name': companyName,
          'person-name': name,
          'email': emailAddress,
          'phone-number': phoneNumber,
          'type': type,
          'message': message
        })
      }).then(res => res.json())
      if(res?.status !== 'mail_sent'){
        alert(res?.message)
        setSending(false)
        return
      }
    } catch(err){
      alert('送信に失敗しました。しばらくしてからもう一度お試しください')
      setSending(false)
      return
    }
    setSending(false)
    setSent(true)
  }

  return <Outer>
    <Contact>Contact</Contact>
    {
      sent ? <SentMessage>お問い合わせありがとうございます<br />送信完了いたしました。</SentMessage> : <>
      <InputOuter>
        <CompanyName>会社名</CompanyName>
        <Input type='text' name='company-name' value={companyName} onChange={e => setCompanyName(e.currentTarget.value)}></Input>
        <Name>お名前</Name>
        <Input type='text' name='name' value={name} onChange={e => setName(e.currentTarget.value)}></Input>
        <Email>メールアドレス</Email>
        <Input type='email' name='email' value={emailAddress} onChange={e => setEmailAddress(e.currentTarget.value)}></Input>
        <Tel>電話番号</Tel>
        <Input type='tel' name='tel' value={phoneNumber} onChange={e => setPhoneNumber(e.currentTarget.value)}></Input>
        <Content>お問合せ内容</Content>
        <RadioContainer>
          <Label><Radio type='radio' name='type' checked={type === 'PRのご相談'} onChange={() => setType('PRのご相談')}/>PRのご相談</Label>
          <Label><Radio type='radio' name='type' checked={type === '商品の貸し出しのお問い合わせ'} onChange={() => setType('商品の貸し出しのお問い合わせ')}/>商品の貸し出しのお問い合わせ</Label>
          <Label><Radio type='radio' name='type' checked={type === '取材について'} onChange={() => setType('取材について')}/>取材について</Label>
          <Label><Radio type='radio' name='type' checked={type === '美容インフルエンサー募集'} onChange={() => setType('美容インフルエンサー募集')}/>美容インフルエンサー募集</Label>
          <Label><Radio type='radio' name='type' checked={type === 'その他'} onChange={() => setType('その他')}/>その他</Label>
        </RadioContainer>
        <Message>メッセージ</Message>
        <Textarea as="textarea" name='message' value={message} onChange={e => setMessage(e.currentTarget.value)}></Textarea>
        <Text>「美容インフルエンサー募集」に関して<br/>
審査後、ご登録された方には、栗田 PR オフィスが取り扱う美容の新製品情報などをセレクトしご案内いたします。審査制となりますため、「メッセージ」にご自身の SNS アカウントをご記入いただけますと幸いです。ご連絡にお時間がかかる場合や、回答できかねる場合がございますので、あらかじめご了承ください。</Text>
        <SendButton onClick={send} disabled={sending}>{sending ? '送信中' : '送信'}</SendButton>
      </InputOuter>
      
      </>
    }
  </Outer>
}