import * as React from "react";
import type { SVGProps } from "react";
const SvgGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 715.635 691.477"
    {...props}
  >
    <defs>
      <clipPath id="uuid-a3efb9da-d3e7-40b1-89f7-3e8b9d9ce6ea">
        <path fill="none" d="M0 0h715.635v691.477H0z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#uuid-a3efb9da-d3e7-40b1-89f7-3e8b9d9ce6ea)"
      data-name="\u30B0\u30EB\u30FC\u30D7 243-2"
    >
      <path
        fill="#efc5ae"
        d="M295.135 46.499c35.92 49.438 24.96 118.633-24.478 154.552-49.437 35.92-118.633 24.96-154.552-24.477-35.919-49.438-24.96-118.633 24.477-154.552C190.02-13.898 259.215-2.938 295.135 46.5"
        data-name="\u30D1\u30B9 947"
        opacity={0.145}
      />
      <path
        fill="none"
        stroke="#002076"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M295.135 46.499c35.92 49.438 24.96 118.633-24.478 154.552-49.437 35.92-118.633 24.96-154.552-24.477-35.919-49.438-24.96-118.633 24.477-154.552C190.02-13.898 259.215-2.938 295.135 46.5Z"
        data-name="\u30D1\u30B9 948"
      />
      <path
        fill="#efc5ae"
        d="M77.365 295.801c58.118-18.884 120.54 12.922 139.423 71.04s-12.922 120.54-71.04 139.423c-58.117 18.884-120.54-12.922-139.423-71.04-18.883-58.118 12.922-120.54 71.04-139.423"
        data-name="\u30D1\u30B9 949"
        opacity={0.145}
      />
      <circle
        cx={111.556}
        cy={401.032}
        r={110.647}
        fill="none"
        stroke="#002076"
        strokeMiterlimit={10}
        strokeWidth={2}
        data-name="\u6955\u5186\u5F62 1"
      />
      <path
        fill="#efc5ae"
        d="M247.17 579.95c0-61.109 49.538-110.647 110.647-110.647s110.647 49.538 110.647 110.647-49.536 110.645-110.643 110.647c-61.109.002-110.649-49.534-110.651-110.643v-.004"
        data-name="\u30D1\u30B9 950"
        opacity={0.145}
      />
      <path
        fill="none"
        stroke="#002076"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M247.17 579.95c0-61.109 49.538-110.647 110.647-110.647s110.647 49.538 110.647 110.647-49.536 110.645-110.643 110.647c-61.109.002-110.649-49.534-110.651-110.643v-.004Z"
        data-name="\u30D1\u30B9 951"
      />
      <path
        fill="#efc5ae"
        d="M569.884 506.267c-58.118-18.883-89.924-81.305-71.04-139.423 18.883-58.118 81.305-89.924 139.423-71.04s89.923 81.305 71.04 139.423c-18.884 58.118-81.305 89.923-139.423 71.04"
        data-name="\u30D1\u30B9 952"
        opacity={0.145}
      />
      <circle
        cx={604.077}
        cy={401.031}
        r={110.647}
        fill="none"
        stroke="#002076"
        strokeMiterlimit={10}
        strokeWidth={2}
        data-name="\u6955\u5186\u5F62 2"
      />
      <path
        fill="#efc5ae"
        d="M599.532 176.572c-35.919 49.438-105.114 60.398-154.552 24.48-49.438-35.92-60.398-105.114-24.48-154.552C456.42-2.938 525.614-13.898 575.052 22.019c49.439 35.918 60.4 105.114 24.481 154.553"
        data-name="\u30D1\u30B9 953"
        opacity={0.145}
      />
      <path
        fill="none"
        stroke="#002076"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M599.532 176.572c-35.919 49.438-105.114 60.398-154.552 24.48-49.438-35.92-60.398-105.114-24.48-154.552C456.42-2.938 525.614-13.898 575.052 22.019c49.439 35.918 60.4 105.114 24.481 154.553Z"
        data-name="\u30D1\u30B9 954"
      />
      <path
        fill="#002076"
        d="M290.155 313.183a6.516 6.516 0 0 0 3.3 1.234 5.135 5.135 0 0 0 4.468-2.033 7.415 7.415 0 0 0 1.461-4.423 7.03 7.03 0 0 0-1.181-4.044c-1.333-1.966-3.582-2.948-6.747-2.946-.185 0-.364 0-.535.009s-.373.025-.6.055l-.044-.56a18.61 18.61 0 0 0 5.415-2.856 5.218 5.218 0 0 0 1.976-4.255 4.26 4.26 0 0 0-1.557-3.564 5.592 5.592 0 0 0-3.557-1.225 5.822 5.822 0 0 0-4.167 1.741 13.24 13.24 0 0 0-2.126 2.921l-.667-.151a9.859 9.859 0 0 1 3.2-5.221 8.2 8.2 0 0 1 5.413-1.953 6.848 6.848 0 0 1 5.081 1.8 5.709 5.709 0 0 1 .311 7.992 13.691 13.691 0 0 1-2.6 2.212 11.124 11.124 0 0 1 3.307 2.063 7.6 7.6 0 0 1 2.342 5.716 9.743 9.743 0 0 1-3.214 7.2c-2.14 2.077-5.186 3.115-9.139 3.115a6.483 6.483 0 0 1-3.706-.785 2.16 2.16 0 0 1-1.065-1.686 1.87 1.87 0 0 1 .355-1.074 1.3 1.3 0 0 1 1.151-.516 6.418 6.418 0 0 1 3.124 1.235"
        data-name="\u30D1\u30B9 955"
      />
      <path
        fill="#002076"
        d="M323.669 313a8.014 8.014 0 0 1-6.561 3.157c-2.522 0-4.817-1.182-6.886-3.546s-3.102-5.507-3.1-9.429a16.96 16.96 0 0 1 6.015-13.019 18.185 18.185 0 0 1 12.068-4.555l.106.709c-1.589.27-3.141.721-4.628 1.343a11.215 11.215 0 0 0-3.469 2.374 18.388 18.388 0 0 0-2.942 3.93 16.667 16.667 0 0 0-1.892 4.727 15.898 15.898 0 0 1 2.3-1.2 9.299 9.299 0 0 1 3.46-.686 7.722 7.722 0 0 1 5.735 2.363c1.547 1.575 2.32 3.823 2.32 6.744a11.066 11.066 0 0 1-2.525 7.092m-2.309-10.506c-1-2.462-2.704-3.695-5.111-3.7-2.22 0-3.697.659-4.428 1.976a8.483 8.483 0 0 0-.642 3.868 16.66 16.66 0 0 0 1.492 6.851c.994 2.235 2.566 3.353 4.715 3.353a3.912 3.912 0 0 0 3.717-2.181 10.72 10.72 0 0 0 1.161-5.1 13.333 13.333 0 0 0-.9-5.07"
        data-name="\u30D1\u30B9 956"
      />
      <path
        fill="#002076"
        d="M345.742 291.301a20.614 20.614 0 0 1 2.17 9.625 24.09 24.09 0 0 1-1.33 8.057c-1.677 4.74-4.577 7.11-8.7 7.112a8.224 8.224 0 0 1-6.96-3.845c-1.948-2.733-2.922-6.45-2.923-11.15a21.516 21.516 0 0 1 1.955-9.538c1.763-3.623 4.47-5.435 8.122-5.434 3.293 0 5.849 1.725 7.668 5.176m-3.265 20.112a32.56 32.56 0 0 0 1.183-10 39.09 39.09 0 0 0-.709-8.329c-.889-3.863-2.55-5.796-4.984-5.8-2.434-.004-4.103 1.985-5.007 5.968a39.906 39.906 0 0 0-.709 8.372 33.376 33.376 0 0 0 .73 7.793c.917 3.636 2.635 5.453 5.156 5.452 2.105 0 3.551-1.152 4.34-3.456"
        data-name="\u30D1\u30B9 957"
      />
      <path
        fill="#002076"
        d="M353.315 287.994a6.265 6.265 0 1 1-1.836 4.424 6.062 6.062 0 0 1 1.836-4.424m1.526 7.335a4.113 4.113 0 1 0-1.213-2.911 3.965 3.965 0 0 0 1.213 2.91"
        data-name="\u30D1\u30B9 958"
      />
      <path
        fill="#002076"
        d="M378.91 314.869c1.679-.157 2.723-.489 3.14-1s.621-1.663.621-3.472v-19.052a4.606 4.606 0 0 0-.7-3.029 5.016 5.016 0 0 0-3.064-.924v-.818h11.6c3.724 0 6.575.724 8.553 2.172a6.752 6.752 0 0 1 2.962 5.714c0 2.993-1.095 5.163-3.286 6.51a15.468 15.468 0 0 1-8.229 2.019c-.47 0-1.077-.011-1.814-.032s-1.285-.033-1.644-.033v7.992c0 1.654.255 2.694.764 3.12a6.678 6.678 0 0 0 3.405.834v.815H378.91v-.816Zm14.846-25.844a11.838 11.838 0 0 0-4.727-.818 3.094 3.094 0 0 0-1.665.269 1.61 1.61 0 0 0-.311 1.191v11.474c.789.07 1.304.113 1.547.127.245.016.48.023.709.023 1.635.074 3.265-.22 4.771-.861 2.103-1.043 3.156-2.999 3.157-5.866a5.519 5.519 0 0 0-3.481-5.54"
        data-name="\u30D1\u30B9 959"
      />
      <path
        fill="#002076"
        d="M403.166 314.869c1.69-.157 2.742-.485 3.157-.984.415-.5.624-1.66.625-3.484v-19.054c0-1.547-.233-2.56-.7-3.041a5.114 5.114 0 0 0-3.082-.912v-.818h12.18c2.153-.061 4.3.266 6.337.966 3.208 1.216 4.812 3.454 4.811 6.714a6.152 6.152 0 0 1-2.136 5.04 11.748 11.748 0 0 1-5.811 2.318l9.065 11.166a6.072 6.072 0 0 0 1.677 1.492c.667.346 1.399.55 2.149.6v.817h-7.089l-11-13.556-1.929.107v8.673c0 1.626.246 2.657.739 3.094a6.16 6.16 0 0 0 3.233.866v.817h-12.226v-.821Zm15.038-15.253a5.341 5.341 0 0 0 3.5-5.4 5.181 5.181 0 0 0-3.136-5.211 11.03 11.03 0 0 0-4.447-.75 5.623 5.623 0 0 0-2.288.268c-.278.178-.417.6-.417 1.253v10.83c2.3.024 4.59-.31 6.788-.989"
        data-name="\u30D1\u30B9 960"
      />
      <path
        fill="#002076"
        d="M237.417 354.35a15.876 15.876 0 0 0 2.617 4.727 8.2 8.2 0 0 0 6.5 3.073 5.36 5.36 0 0 0 3.636-1.334 4.658 4.658 0 0 0 1.533-3.717 5.194 5.194 0 0 0-1.543-3.738 27.301 27.301 0 0 0-4.245-3.029l-3.752-2.341a13.621 13.621 0 0 1-2.7-2.17 6.729 6.729 0 0 1-1.869-4.6 7.511 7.511 0 0 1 2.212-5.54 7.92 7.92 0 0 1 5.864-2.214c1.24.046 2.462.296 3.62.741.784.312 1.592.56 2.416.741.415.049.83-.093 1.13-.385.238-.328.402-.703.482-1.1h.9l.968 9.366h-1.054c-.716-2.821-1.865-4.79-3.448-5.908a8.122 8.122 0 0 0-4.694-1.675 4.931 4.931 0 0 0-3.148 1.063 3.558 3.558 0 0 0-1.343 2.955 4.316 4.316 0 0 0 1.03 2.9 13.209 13.209 0 0 0 3.2 2.557l3.863 2.386a20.05 20.05 0 0 1 5.116 4.1 7.07 7.07 0 0 1 1.466 4.475 7.421 7.421 0 0 1-2.608 5.779 9.666 9.666 0 0 1-6.672 2.319 12.969 12.969 0 0 1-4.264-.773 16.184 16.184 0 0 0-2.545-.774 1.09 1.09 0 0 0-1.042.472 2.933 2.933 0 0 0-.354 1.01h-.968l-1.311-9.366h1.007Z"
        data-name="\u30D1\u30B9 961"
      />
      <path
        fill="#002076"
        d="M259.911 334.092h24.877l.236 7.476h-1.053c-.515-2.347-1.17-3.875-1.966-4.584-.795-.709-2.467-1.064-5.016-1.065h-2.45v22.514c0 1.695.267 2.745.8 3.152a7.325 7.325 0 0 0 3.479.8v.817H265.99v-.817c1.862-.143 3.022-.449 3.479-.919s.686-1.653.686-3.55v-22h-2.469c-2.435 0-4.093.351-4.973 1.054s-1.542 2.234-1.987 4.595h-1.073l.258-7.473Z"
        data-name="\u30D1\u30B9 962"
      />
      <path
        fill="#002076"
        d="M286.421 362.385c1.69-.157 2.742-.485 3.157-.984s.624-1.66.625-3.484v-19.054c0-1.547-.233-2.56-.7-3.041a5.114 5.114 0 0 0-3.082-.912v-.818h12.18c2.153-.061 4.3.266 6.337.966 3.208 1.216 4.812 3.454 4.811 6.714a6.152 6.152 0 0 1-2.136 5.04 11.748 11.748 0 0 1-5.811 2.318l9.065 11.166a6.072 6.072 0 0 0 1.677 1.492c.667.346 1.399.55 2.149.6v.817h-7.089l-11-13.556-1.935.103v8.673c0 1.626.246 2.657.739 3.094a6.16 6.16 0 0 0 3.233.866v.817h-12.22v-.817Zm15.038-15.253a5.341 5.341 0 0 0 3.5-5.4 5.181 5.181 0 0 0-3.136-5.211 11.033 11.033 0 0 0-4.447-.75 5.623 5.623 0 0 0-2.288.268c-.278.178-.417.6-.417 1.253v10.83c2.3.023 4.59-.31 6.788-.989"
        data-name="\u30D1\u30B9 963"
      />
      <path
        fill="#002076"
        d="M315.919 363.201v-.817a3.573 3.573 0 0 0 2.548-1.278 35.135 35.135 0 0 0 2.907-5.94l9.165-21.592h.876l10.962 24.942c.407 1.113 1 2.15 1.751 3.066.642.471 1.405.75 2.2.8v.817h-11.186v-.817a9.792 9.792 0 0 0 2.487-.417c.373-.163.56-.566.56-1.209a5.344 5.344 0 0 0-.215-1.132 12.286 12.286 0 0 0-.6-1.711l-1.762-4.232h-11.596c-1.142 2.88-1.824 4.636-2.047 5.271-.19.479-.303.985-.334 1.5a1.554 1.554 0 0 0 .9 1.54 7.147 7.147 0 0 0 2.1.385v.817l-8.716.007Zm18.95-11.3-5.042-12.117-5.067 12.117h10.109Z"
        data-name="\u30D1\u30B9 964"
      />
      <path
        fill="#002076"
        d="M347.904 334.092h24.877l.236 7.476h-1.053c-.515-2.347-1.17-3.875-1.966-4.584-.795-.709-2.467-1.064-5.016-1.065h-2.45v22.514c0 1.695.267 2.745.8 3.152a7.325 7.325 0 0 0 3.479.8v.817h-12.828v-.817c1.862-.143 3.022-.449 3.479-.919.457-.47.686-1.653.686-3.55v-22h-2.469c-2.435 0-4.093.351-4.973 1.054s-1.542 2.234-1.987 4.595h-1.073l.258-7.473Z"
        data-name="\u30D1\u30B9 965"
      />
      <path
        fill="#002076"
        d="M374.414 362.385a6.443 6.443 0 0 0 3.147-.866c.48-.407.72-1.436.72-3.087v-19.57c0-1.563-.236-2.583-.709-3.062s-1.525-.776-3.157-.891v-.818h23.373l.15 6.3h-1.117c-.371-1.94-.93-3.194-1.675-3.764s-2.407-.855-4.984-.854h-5.989c-.646 0-1.046.104-1.2.311-.185.36-.263.766-.225 1.17v9.817h6.79c1.874 0 3.07-.277 3.587-.832a7.217 7.217 0 0 0 1.246-3.379h1.031v10.163h-1.031a7.313 7.313 0 0 0-1.269-3.384c-.515-.55-1.703-.826-3.564-.827h-6.79v10.892c0 .875.276 1.39.827 1.547 1.643.225 3.302.304 4.959.236a16.7 16.7 0 0 0 6.6-.977c1.42-.651 2.696-2.23 3.828-4.738h1.225l-2 7.434h-23.773v-.821Z"
        data-name="\u30D1\u30B9 966"
      />
      <path
        fill="#002076"
        d="M422.106 334.285c.927.303 1.874.54 2.835.709.477.047.955-.084 1.343-.366.3-.297.531-.656.678-1.052h.968l.343 9.259h-1.01a16.878 16.878 0 0 0-2.793-4.769 8.887 8.887 0 0 0-6.767-2.749 10.213 10.213 0 0 0-7.444 3.212c-2.142 2.142-3.213 5.669-3.212 10.58 0 4.053 1.067 7.217 3.2 9.494a10.4 10.4 0 0 0 7.905 3.416c.626-.008 1.25-.058 1.869-.15a11.517 11.517 0 0 0 2.041-.472 5.956 5.956 0 0 0 1.953-.89c.276-.297.417-.695.389-1.1v-7.01c0-1.698-.24-2.756-.722-3.175a5.831 5.831 0 0 0-3.147-.778v-.815h11.212v.815a4.2 4.2 0 0 0-2.168.539c-.6.457-.9 1.402-.9 2.835v8.657c0 .515-1.264 1.203-3.791 2.063a22.64 22.64 0 0 1-7.293 1.288 16.217 16.217 0 0 1-10.871-3.759 13.856 13.856 0 0 1-4.878-11.128 15.325 15.325 0 0 1 3.889-10.526 14.4 14.4 0 0 1 11.344-4.834c1.7 0 3.392.238 5.026.709"
        data-name="\u30D1\u30B9 967"
      />
      <path
        fill="#002076"
        d="M445.844 334.092v.818a6.656 6.656 0 0 0-2.319.236 1.147 1.147 0 0 0-.774 1.2c.01.238.053.474.129.7.144.393.34.765.582 1.107l6.475 9.494 6.316-9.644c.211-.32.38-.667.5-1.031.065-.194.105-.396.12-.6a1.231 1.231 0 0 0-1.01-1.248 8.064 8.064 0 0 0-2.063-.213v-.818h9.614v.818a4.442 4.442 0 0 0-2.379.794 15.858 15.858 0 0 0-3.668 4.231l-6.5 9.927v8.555c0 1.702.276 2.764.827 3.187a7.636 7.636 0 0 0 3.708.781v.817h-13.424v-.817c1.99-.113 3.224-.41 3.7-.891s.716-1.679.72-3.592v-7.634l-5.757-8.442a51.36 51.36 0 0 0-4.19-5.6 4.23 4.23 0 0 0-2.921-1.32v-.818l12.314.003Z"
        data-name="\u30D1\u30B9 968"
      />
      <path
        fill="#002076"
        d="M467.262 350.578a7.575 7.575 0 1 1-2.21 5.348 7.265 7.265 0 0 1 2.21-5.348m.581 10.117a6.751 6.751 0 1 0-1.973-4.769 6.478 6.478 0 0 0 1.973 4.77m.486-.923c.399.021.799-.017 1.186-.114.2-.076.308-.285.308-.628v-6.417a.576.576 0 0 0-.319-.593 3.418 3.418 0 0 0-1.176-.137v-.514h4.329a5.226 5.226 0 0 1 2.664.6 1.856 1.856 0 0 1 1.019 1.665 1.744 1.744 0 0 1-.817 1.466 4.47 4.47 0 0 1-2.179.727v.074a2.983 2.983 0 0 1 2.066 2.186c.305 1.114.561 1.67.769 1.67a.589.589 0 0 0 .568-.429c.104-.38.171-.768.2-1.16h.537a3.799 3.799 0 0 1-.322 1.43 1.61 1.61 0 0 1-1.547.837 1.421 1.421 0 0 1-1.4-.678 4.763 4.763 0 0 1-.3-1.7c.043-.62-.17-1.23-.591-1.688a3.6 3.6 0 0 0-2.029-.343v3a.616.616 0 0 0 .415.656c.353.082.716.111 1.077.086v.516h-4.468l.01-.512Zm5.285-4.49a1.588 1.588 0 0 0 .922-1.66 1.662 1.662 0 0 0-.487-1.28 2.22 2.22 0 0 0-1.552-.456 2.14 2.14 0 0 0-.869.146.58.58 0 0 0-.322.593v2.888a7.176 7.176 0 0 0 2.309-.236"
        data-name="\u30D1\u30B9 969"
      />
      <path
        fill="none"
        stroke="#474645"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M431.762 78.792a233.519 233.519 0 0 0-147.87.051"
        data-name="\u30D1\u30B9 970"
      />
      <path
        fill="#474645"
        d="m432.257 69.761 11.369 13.366-17.261 3.164 5.892-16.53Z"
        data-name="\u30D1\u30B9 971"
      />
      <path
        fill="#474645"
        d="m283.408 69.805-11.386 13.352 17.256 3.183-5.87-16.535Z"
        data-name="\u30D1\u30B9 972"
      />
      <path
        fill="none"
        stroke="#474645"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M145.808 179.103a233.522 233.522 0 0 0-45.651 140.648"
        data-name="\u30D1\u30B9 973"
      />
      <path
        fill="#474645"
        d="m137.371 175.838 16.226-6.68-2.325 17.393-13.901-10.713Z"
        data-name="\u30D1\u30B9 974"
      />
      <path
        fill="#474645"
        d="m91.416 317.418 9.179 14.955 8.365-15.424-17.544.469Z"
        data-name="\u30D1\u30B9 975"
      />
      <path
        fill="none"
        stroke="#474645"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M152.844 482.057A233.519 233.519 0 0 0 272.5 568.939"
        data-name="\u30D1\u30B9 976"
      />
      <path
        fill="#474645"
        d="m147.133 489.074-1.339-17.5 15.823 7.587-14.484 9.913Z"
        data-name="\u30D1\u30B9 977"
      />
      <path
        fill="#474645"
        d="m267.581 576.529 17.06-4.109-12.087-12.72-4.973 16.829Z"
        data-name="\u30D1\u30B9 978"
      />
      <path
        fill="none"
        stroke="#474645"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M443.145 568.986a233.51 233.51 0 0 0 119.6-86.953"
        data-name="\u30D1\u30B9 979"
      />
      <path
        fill="#474645"
        d="M448.053 576.584 431 572.452l12.1-12.7 4.953 16.832Z"
        data-name="\u30D1\u30B9 980"
      />
      <path
        fill="#474645"
        d="m568.449 489.056 1.364-17.495-15.833 7.566 14.469 9.929Z"
        data-name="\u30D1\u30B9 981"
      />
      <path
        fill="none"
        stroke="#474645"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M615.527 319.754a233.5 233.5 0 0 0-45.739-140.618"
        data-name="\u30D1\u30B9 982"
      />
      <path
        fill="#474645"
        d="m624.268 317.434-9.2 14.941-8.342-15.437 17.542.496Z"
        data-name="\u30D1\u30B9 983"
      />
      <path
        fill="#474645"
        d="m578.23 175.883-16.217-6.7 2.3 17.4 13.917-10.7Z"
        data-name="\u30D1\u30B9 984"
      />
      <path
        fill="#002076"
        d="M169.218 130.307a3.363 3.363 0 0 0 2.24-.79 5.15 5.15 0 0 0 .5-2.837v-11.435a2.845 2.845 0 0 0-.482-1.985 3.7 3.7 0 0 0-2.186-.542v-.523h5.551l6.467 14.026 6.309-14.026h5.6v.523a3.35 3.35 0 0 0-2.022.57 2.935 2.935 0 0 0-.452 1.957v12.521a2.9 2.9 0 0 0 .452 1.971 3.633 3.633 0 0 0 2.022.57v.523h-7.879v-.523a3.447 3.447 0 0 0 2.124-.612 3.855 3.855 0 0 0 .421-2.26v-12.637l-7.219 16.032h-.385l-7.087-15.372v11.222c0 1.538.225 2.54.674 3.008a3.42 3.42 0 0 0 1.966.619v.523h-6.614v-.523Z"
        data-name="\u30D1\u30B9 985"
      />
      <path
        fill="#002076"
        d="M203.848 119.272a4.907 4.907 0 0 1 1.457 3.8h-8.731a7.31 7.31 0 0 0 1.445 4.637 4.029 4.029 0 0 0 3.094 1.454 3.893 3.893 0 0 0 2.432-.753 7.585 7.585 0 0 0 1.828-2.14l.482.165a7.85 7.85 0 0 1-2.11 3.236 5.262 5.262 0 0 1-3.789 1.485 4.636 4.636 0 0 1-3.993-1.952 7.48 7.48 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.237 5.237 0 0 1 4.261-2.006 4.864 4.864 0 0 1 3.4 1.339m-6.589 1.054a5.457 5.457 0 0 0-.632 1.869h5.8a5.763 5.763 0 0 0-.523-2.048 2.375 2.375 0 0 0-2.256-1.209 2.627 2.627 0 0 0-2.392 1.389"
        data-name="\u30D1\u30B9 986"
      />
      <path
        fill="#002076"
        d="M215.632 118.277a4.4 4.4 0 0 1 1.059.8v-4.374c.03-.395-.033-.79-.185-1.156-.125-.211-.421-.315-.887-.315-.111 0-.208.005-.289.012l-.551.056v-.454l1.128-.289c.412-.11.824-.225 1.237-.343.413-.118.775-.233 1.086-.345.146-.046.391-.132.729-.26l.083.026-.028 1.445-.026 1.616c-.008.557-.014 1.1-.014 1.642l-.028 11.233c-.033.429.043.86.22 1.251.146.239.537.357 1.169.357.1 0 .2 0 .3-.007.1-.005.2-.016.3-.033v.451c-.055.017-.72.246-1.994.686l-2.158.811-.1-.123v-1.688a7.259 7.259 0 0 1-1.334 1.2 4.34 4.34 0 0 1-2.323.618 4.626 4.626 0 0 1-3.787-1.807 6.494 6.494 0 0 1-1.452-4.187 7.846 7.846 0 0 1 1.741-5.121 5.3 5.3 0 0 1 4.255-2.138 3.893 3.893 0 0 1 1.841.44m.4 10.7c.374-.294.612-.727.66-1.2v-5.9a3 3 0 0 0-.956-2.524 3.083 3.083 0 0 0-1.864-.736 3.016 3.016 0 0 0-2.694 1.533 6.978 6.978 0 0 0-.963 3.775 7.618 7.618 0 0 0 1.024 3.946 3.334 3.334 0 0 0 3.06 1.732 2.4 2.4 0 0 0 1.732-.632"
        data-name="\u30D1\u30B9 987"
      />
      <path
        fill="#002076"
        d="M222.408 130.443a3.5 3.5 0 0 0 1.857-.5c.255-.243.383-.9.384-1.973v-6.561a4.167 4.167 0 0 0-.123-1.237.831.831 0 0 0-.866-.563c-.1-.001-.201.009-.3.03-.1.016-.373.09-.832.22v-.429l.591-.192c1.6-.523 2.718-.917 3.354-1.183a1.83 1.83 0 0 1 .5-.165.792.792 0 0 1 .026.209v9.869a3.213 3.213 0 0 0 .378 1.966c.5.35 1.101.53 1.712.509v.385h-6.682l.001-.385Zm2.186-18.383a1.36 1.36 0 0 1 1.017-.424c.79 0 1.43.64 1.431 1.429v.007c.006.38-.147.745-.421 1.007a1.425 1.425 0 0 1-2.439-1.007c-.005-.38.144-.744.412-1.012"
        data-name="\u30D1\u30B9 988"
      />
      <path
        fill="#002076"
        d="M232.87 124.76c1.61-.9 3.299-1.651 5.046-2.242v-1.164a4.816 4.816 0 0 0-.275-1.952 1.96 1.96 0 0 0-1.939-.922c-.469 0-.929.124-1.334.359a1.135 1.135 0 0 0-.632 1.021c.009.187.032.373.069.556.034.165.057.332.069.5.043.425-.153.839-.509 1.074a1.287 1.287 0 0 1-.688.178 1.156 1.156 0 0 1-.949-.4 1.393 1.393 0 0 1-.329-.9 2.86 2.86 0 0 1 1.19-2.013 5.1 5.1 0 0 1 3.485-1.052c1.78 0 2.985.577 3.617 1.732.386.868.56 1.816.509 2.765v5.512c-.02.37.017.74.109 1.1.086.335.398.56.743.537.18.005.358-.029.523-.1.25-.136.489-.292.714-.466v.715a4.474 4.474 0 0 1-.949.88 2.766 2.766 0 0 1-1.566.523 1.527 1.527 0 0 1-1.355-.605 2.718 2.718 0 0 1-.461-1.443c-.555.5-1.153.95-1.786 1.346a4.675 4.675 0 0 1-2.379.743 2.857 2.857 0 0 1-2.048-.831 2.8 2.8 0 0 1-.866-2.112 3.917 3.917 0 0 1 1.994-3.326m5.046-1.554c-.912.285-1.788.672-2.612 1.154-1.32.817-1.98 1.742-1.98 2.777a2.112 2.112 0 0 0 .824 1.843c.348.254.769.389 1.2.385a3.293 3.293 0 0 0 1.735-.518c.495-.243.815-.74.832-1.292l.001-4.349Z"
        data-name="\u30D1\u30B9 989"
      />
      <path
        fill="#002076"
        d="M152.082 161.984a3.19 3.19 0 0 0 2.022-.63 3.965 3.965 0 0 0 .4-2.23v-12.2a2.94 2.94 0 0 0-.447-1.945 3.269 3.269 0 0 0-1.973-.584v-.523h7.8c1.378-.039 2.752.17 4.056.618a4.286 4.286 0 0 1 3.08 4.3 3.936 3.936 0 0 1-1.375 3.232 7.506 7.506 0 0 1-3.719 1.484l5.8 7.145c.295.383.658.707 1.072.956.427.22.895.35 1.374.382v.523h-4.532l-7.043-8.676-1.235.069v5.549c0 1.042.158 1.702.475 1.98a3.919 3.919 0 0 0 2.07.556v.523h-7.825v-.529Zm9.625-9.762a3.419 3.419 0 0 0 2.24-3.458 3.313 3.313 0 0 0-2.006-3.333 7.015 7.015 0 0 0-2.846-.482 3.54 3.54 0 0 0-1.464.173c-.18.113-.269.382-.269.8v6.93a14.235 14.235 0 0 0 4.345-.632"
        data-name="\u30D1\u30B9 990"
      />
      <path
        fill="#002076"
        d="M180.285 150.95a4.907 4.907 0 0 1 1.457 3.8h-8.731a7.31 7.31 0 0 0 1.445 4.637 4.029 4.029 0 0 0 3.094 1.454 3.893 3.893 0 0 0 2.432-.753 7.585 7.585 0 0 0 1.828-2.14l.482.165a7.85 7.85 0 0 1-2.11 3.236 5.262 5.262 0 0 1-3.789 1.485 4.636 4.636 0 0 1-3.993-1.952 7.48 7.48 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.639-4.765 5.237 5.237 0 0 1 4.261-2.006 4.864 4.864 0 0 1 3.4 1.339m-6.591 1.048a5.46 5.46 0 0 0-.632 1.869h5.8a5.763 5.763 0 0 0-.523-2.048 2.375 2.375 0 0 0-2.256-1.209 2.627 2.627 0 0 0-2.389 1.388"
        data-name="\u30D1\u30B9 991"
      />
      <path
        fill="#002076"
        d="M183.388 162.121a3.264 3.264 0 0 0 1.732-.516c.36-.425.524-.981.454-1.533V146.64a3.477 3.477 0 0 0-.151-1.251 1.107 1.107 0 0 0-1.114-.577c-.14.001-.28.015-.419.04-.151.028-.341.07-.57.125v-.454c1.237-.333 2.727-.773 4.468-1.32a.105.105 0 0 1 .116.083c.019.118.026.238.021.357v16.488c-.077.55.073 1.109.414 1.547a3.2 3.2 0 0 0 1.7.447v.385h-6.654l.003-.389Z"
        data-name="\u30D1\u30B9 992"
      />
      <path
        fill="#002076"
        d="M193.826 156.438c1.61-.9 3.299-1.651 5.046-2.242v-1.164a4.817 4.817 0 0 0-.275-1.952 1.96 1.96 0 0 0-1.939-.922c-.469 0-.929.124-1.334.359-.39.19-.637.587-.636 1.021.009.187.032.373.069.556.034.165.057.332.069.5.043.425-.153.839-.509 1.074a1.287 1.287 0 0 1-.688.178 1.156 1.156 0 0 1-.949-.4 1.393 1.393 0 0 1-.329-.9 2.86 2.86 0 0 1 1.19-2.013 5.1 5.1 0 0 1 3.485-1.052c1.78 0 2.985.577 3.617 1.732.386.868.56 1.816.509 2.765v5.512c-.02.37.017.74.109 1.1.086.335.398.56.743.537.18.005.358-.029.523-.1.25-.136.489-.292.714-.466v.714a4.474 4.474 0 0 1-.949.88 2.767 2.767 0 0 1-1.566.523 1.527 1.527 0 0 1-1.355-.605 2.718 2.718 0 0 1-.461-1.443c-.555.5-1.153.95-1.786 1.346a4.675 4.675 0 0 1-2.379.743 2.857 2.857 0 0 1-2.048-.831 2.8 2.8 0 0 1-.871-2.119 3.917 3.917 0 0 1 2-3.331m5.046-1.554c-.912.285-1.788.672-2.612 1.154-1.32.817-1.98 1.742-1.98 2.777a2.112 2.112 0 0 0 .824 1.843c.348.254.769.389 1.2.385a3.293 3.293 0 0 0 1.731-.505c.495-.243.815-.74.832-1.292l.005-4.362Z"
        data-name="\u30D1\u30B9 993"
      />
      <path
        fill="#002076"
        d="M210.723 149.831v.989h-2.805l-.026 7.919a5.35 5.35 0 0 0 .178 1.582c.152.583.692.979 1.294.949a1.55 1.55 0 0 0 .859-.234c.307-.212.586-.462.831-.743l.359.3-.3.414a4.412 4.412 0 0 1-1.513 1.36 3.43 3.43 0 0 1-1.54.4 2.169 2.169 0 0 1-2.2-1.445 5.971 5.971 0 0 1-.315-2.172v-8.331h-1.505a.367.367 0 0 1-.1-.081.17.17 0 0 1-.035-.111.33.33 0 0 1 .062-.213c.124-.124.255-.241.392-.35.488-.387.945-.813 1.367-1.272.28-.324.938-1.183 1.973-2.578a.433.433 0 0 1 .213.028c.023.019.035.088.035.206v3.383h2.776Z"
        data-name="\u30D1\u30B9 994"
      />
      <path
        fill="#002076"
        d="M212.591 162.12a3.5 3.5 0 0 0 1.857-.5c.255-.243.383-.9.384-1.973v-6.561a4.17 4.17 0 0 0-.123-1.237.831.831 0 0 0-.866-.563c-.1-.001-.201.009-.3.03-.1.016-.373.09-.832.22v-.429l.591-.192c1.6-.523 2.718-.917 3.354-1.183a1.83 1.83 0 0 1 .5-.165.797.797 0 0 1 .026.209v9.869a3.213 3.213 0 0 0 .378 1.966c.5.35 1.101.53 1.712.509v.385h-6.681v-.385Zm2.186-18.383a1.36 1.36 0 0 1 1.014-.425c.79 0 1.43.64 1.431 1.429v.007c.006.38-.147.745-.421 1.007a1.425 1.425 0 0 1-2.439-1.007c-.005-.38.144-.744.412-1.012"
        data-name="\u30D1\u30B9 995"
      />
      <path
        fill="#002076"
        d="M222.304 151.497a5.809 5.809 0 0 1 4.475-1.89 6.11 6.11 0 0 1 4.509 1.788 6.427 6.427 0 0 1 1.788 4.757 7.082 7.082 0 0 1-1.732 4.757 5.633 5.633 0 0 1-4.482 2.02 5.907 5.907 0 0 1-4.468-1.932 6.814 6.814 0 0 1-1.829-4.868 6.582 6.582 0 0 1 1.739-4.632m2.2-.35c-.919.834-1.378 2.273-1.378 4.317a9.7 9.7 0 0 0 1.108 4.567 3.394 3.394 0 0 0 3.078 2.117 2.618 2.618 0 0 0 2.376-1.417 7.254 7.254 0 0 0 .834-3.712 9.816 9.816 0 0 0-1.061-4.482 3.324 3.324 0 0 0-3.071-2.1 2.73 2.73 0 0 0-1.887.714"
        data-name="\u30D1\u30B9 996"
      />
      <path
        fill="#002076"
        d="M234.588 162.122a2.5 2.5 0 0 0 1.367-.487c.33-.476.469-1.057.392-1.63v-6.985a3.177 3.177 0 0 0-.165-1.227 1.082 1.082 0 0 0-1.072-.519c-.083 0-.167.005-.255.014s-.194.021-.322.04v-.48c.377-.11 1.265-.395 2.666-.854l1.294-.42c.067 0 .105.023.116.069a.7.7 0 0 1 .021.194v2.02c.608-.6 1.27-1.143 1.976-1.623a4.116 4.116 0 0 1 2.224-.674 2.906 2.906 0 0 1 1.7.537c.987.697 1.481 1.939 1.482 3.726v6.422c-.044.51.098 1.017.4 1.429.358.33.838.494 1.323.454v.385h-5.87v-.385a2.36 2.36 0 0 0 1.4-.556 2.832 2.832 0 0 0 .4-1.823v-5.875a3.947 3.947 0 0 0-.438-1.96 1.684 1.684 0 0 0-1.612-.776 2.98 2.98 0 0 0-1.64.549c-.429.314-.83.663-1.2 1.045v7.7a1.6 1.6 0 0 0 .44 1.327c.415.26.9.386 1.389.364v.385h-6.008l-.008-.386Z"
        data-name="\u30D1\u30B9 997"
      />
      <path
        fill="#002076"
        d="M249.989 158.176h.454c.137.847.425 1.661.85 2.406a3.112 3.112 0 0 0 2.812 1.54 2.259 2.259 0 0 0 1.626-.57c.4-.385.619-.922.6-1.478a2.039 2.039 0 0 0-.345-1.114 3.505 3.505 0 0 0-1.209-1.044l-1.54-.88a8.807 8.807 0 0 1-2.488-1.828 3.216 3.216 0 0 1-.8-2.173 3.249 3.249 0 0 1 1.1-2.529 3.977 3.977 0 0 1 2.763-.991c.544.01 1.084.103 1.6.275.321.115.65.207.984.276a.705.705 0 0 0 .357-.069.582.582 0 0 0 .192-.22h.331l.1 3.835h-.426a6.348 6.348 0 0 0-.743-2.075 2.729 2.729 0 0 0-2.46-1.376 1.952 1.952 0 0 0-2.063 1.98c0 .845.633 1.597 1.9 2.256l1.814.975c1.953 1.067 2.93 2.304 2.928 3.713a3.32 3.32 0 0 1-1.214 2.652 4.738 4.738 0 0 1-3.178 1.031 7.346 7.346 0 0 1-1.865-.275 8.468 8.468 0 0 0-1.234-.275.411.411 0 0 0-.29.116.834.834 0 0 0-.192.282h-.357l-.007-4.44Z"
        data-name="\u30D1\u30B9 998"
      />
      <path
        fill="#002076"
        d="M201.8 55.101a28.887 28.887 0 0 1 3.038 13.473 33.658 33.658 0 0 1-1.865 11.277c-2.344 6.638-6.404 9.957-12.18 9.956-3.95 0-7.199-1.794-9.744-5.383-2.728-3.83-4.092-9.032-4.092-15.608a30.1 30.1 0 0 1 2.737-13.355c2.467-5.074 6.256-7.61 11.367-7.61 4.613 0 8.192 2.416 10.739 7.249m-4.572 28.154c1.102-3.226 1.653-7.893 1.653-14a54.636 54.636 0 0 0-.991-11.661c-1.244-5.408-3.57-8.112-6.978-8.113-3.408 0-5.744 2.783-7.007 8.35a55.621 55.621 0 0 0-.994 11.721 46.677 46.677 0 0 0 1.023 10.91c1.283 5.09 3.689 7.634 7.219 7.633 2.948 0 4.973-1.613 6.075-4.838"
        data-name="\u30D1\u30B9 999"
      />
      <path
        fill="#002076"
        d="M222.39 48.137c.012.115.017.231.014.347v36.211c0 1.543.414 2.525 1.241 2.946 1.48.543 3.05.799 4.625.753v.843h-16.992v-.9c2.423-.121 4.007-.452 4.75-.993s1.115-1.714 1.114-3.52V55.972a5.221 5.221 0 0 0-.363-2.195 1.554 1.554 0 0 0-1.563-.753 6.483 6.483 0 0 0-2.029.436c-.834.29-1.612.597-2.334.919v-.842l11.04-5.625h.361a.7.7 0 0 1 .135.225"
        data-name="\u30D1\u30B9 1000"
      />
      <path
        fill="#002076"
        d="M467.813 130.307a4.159 4.159 0 0 0 2.008-.554c.311-.259.467-.917.468-1.976v-12.525a2.924 2.924 0 0 0-.454-1.98 3.481 3.481 0 0 0-2.022-.549v-.523h7.59c2.23-.066 4.445.366 6.486 1.265 3.19 1.512 4.786 4.216 4.785 8.111a9.594 9.594 0 0 1-.859 4 8.212 8.212 0 0 1-2.592 3.2 8.783 8.783 0 0 1-2.283 1.251 15.45 15.45 0 0 1-5.306.8h-7.824l.003-.52Zm5.672-.743c.441.188.922.263 1.4.22a10.249 10.249 0 0 0 5.431-1.308c2.18-1.34 3.271-3.628 3.272-6.867 0-2.888-.898-5.062-2.694-6.522a9.424 9.424 0 0 0-6.119-1.871 2.728 2.728 0 0 0-1.285.206c-.225.137-.336.44-.336.908v14.326c-.049.34.078.681.336.908"
        data-name="\u30D1\u30B9 1001"
      />
      <path
        fill="#002076"
        d="M498.125 119.272a4.907 4.907 0 0 1 1.457 3.8h-8.731a7.31 7.31 0 0 0 1.445 4.637 4.029 4.029 0 0 0 3.094 1.454 3.893 3.893 0 0 0 2.432-.753 7.585 7.585 0 0 0 1.828-2.14l.482.165a7.85 7.85 0 0 1-2.11 3.236 5.262 5.262 0 0 1-3.789 1.485 4.636 4.636 0 0 1-3.993-1.952 7.48 7.48 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.237 5.237 0 0 1 4.261-2.006 4.864 4.864 0 0 1 3.4 1.339m-6.589 1.054a5.46 5.46 0 0 0-.632 1.869h5.8a5.76 5.76 0 0 0-.523-2.048 2.375 2.375 0 0 0-2.256-1.209 2.627 2.627 0 0 0-2.392 1.389"
        data-name="\u30D1\u30B9 1002"
      />
      <path
        fill="#002076"
        d="M503.234 124.739a4.262 4.262 0 0 1-.693-2.379 4.659 4.659 0 0 1 4.894-4.5 6.11 6.11 0 0 1 2.214.475 6.338 6.338 0 0 0 2.323.473c.194 0 .484-.005.873-.019s.672-.021.847-.021h.165v1.122h-2.378c.165.385.294.719.385 1 .15.49.229 1 .234 1.513a4.027 4.027 0 0 1-1.3 2.879 4.7 4.7 0 0 1-3.5 1.313 10.665 10.665 0 0 1-1.237-.123c-.267 0-.62.225-1.059.674a1.85 1.85 0 0 0-.66 1.1c0 .294.321.51.964.646.46.095.93.141 1.4.137 1.61-.073 3.224.061 4.8.4a2.673 2.673 0 0 1 2.08 2.777 3.884 3.884 0 0 1-2.427 3.465c-1.5.83-3.185 1.275-4.9 1.292a6.173 6.173 0 0 1-3.574-.915 2.477 2.477 0 0 1-1.32-1.916c.007-.353.13-.693.348-.97.418-.508.879-.98 1.376-1.41l.9-.811.165-.165a4.053 4.053 0 0 1-.941-.48 1.228 1.228 0 0 1-.547-.977c.03-.415.198-.809.475-1.119a17.809 17.809 0 0 1 2.038-1.99 3.61 3.61 0 0 1-1.946-1.471m1.459 10.228a9.238 9.238 0 0 0 2.976.44 7.628 7.628 0 0 0 3.712-.79 2.279 2.279 0 0 0 1.431-1.946c0-.612-.385-1.022-1.156-1.223a14.406 14.406 0 0 0-2.714-.206 79.805 79.805 0 0 1-2.11-.083c-.2-.009-.526-.046-.977-.109s-.787-.12-1.007-.165c-.111 0-.377.289-.8.866a2.8 2.8 0 0 0-.651 1.623c0 .773.543 1.438 1.3 1.594m4.364-9.445a2.79 2.79 0 0 0 .825-2.3 7.9 7.9 0 0 0-.667-2.942 2.29 2.29 0 0 0-2.221-1.635 1.853 1.853 0 0 0-1.857 1.278 4.67 4.67 0 0 0-.26 1.665 5.668 5.668 0 0 0 .811 3.011 2.411 2.411 0 0 0 2.1 1.332c.457.016.905-.13 1.265-.412"
        data-name="\u30D1\u30B9 1003"
      />
      <path
        fill="#002076"
        d="M515.818 130.443a3.5 3.5 0 0 0 1.857-.5c.255-.243.383-.9.384-1.973v-6.561a4.167 4.167 0 0 0-.123-1.237.831.831 0 0 0-.866-.563c-.1-.001-.201.009-.3.03-.1.016-.373.09-.832.22v-.429l.591-.192c1.6-.523 2.718-.917 3.354-1.183a1.83 1.83 0 0 1 .5-.165.792.792 0 0 1 .026.209v9.869a3.213 3.213 0 0 0 .378 1.966c.5.35 1.101.53 1.712.509v.385h-6.681v-.385Zm2.186-18.383a1.36 1.36 0 0 1 1.017-.424c.79 0 1.43.64 1.431 1.429v.007c.006.38-.147.745-.421 1.007a1.426 1.426 0 0 1-2.439-1.007 1.4 1.4 0 0 1 .412-1.012"
        data-name="\u30D1\u30B9 1004"
      />
      <path
        fill="#002076"
        d="M530.055 118.153v.989h-2.805l-.026 7.919c-.02.533.04 1.067.178 1.582.152.583.692.979 1.294.949.303.008.602-.074.859-.234.307-.212.586-.462.831-.743l.359.3-.3.414a4.413 4.413 0 0 1-1.514 1.36 3.43 3.43 0 0 1-1.54.4 2.169 2.169 0 0 1-2.2-1.445 5.971 5.971 0 0 1-.315-2.172v-8.331h-1.5a.367.367 0 0 1-.1-.081.17.17 0 0 1-.035-.111.33.33 0 0 1 .062-.213c.124-.124.255-.241.392-.35.488-.387.945-.813 1.367-1.272.28-.324.938-1.183 1.973-2.578a.434.434 0 0 1 .213.028c.023.019.035.088.035.206v3.383h2.772Z"
        data-name="\u30D1\u30B9 1005"
      />
      <path
        fill="#002076"
        d="M534.54 124.76c1.61-.9 3.299-1.651 5.046-2.242v-1.164a4.816 4.816 0 0 0-.275-1.952 1.961 1.961 0 0 0-1.939-.922c-.468 0-.929.124-1.334.359-.387.193-.63.589-.629 1.021.009.187.032.373.069.556.034.165.057.332.069.5.043.425-.153.839-.509 1.074a1.287 1.287 0 0 1-.688.178 1.156 1.156 0 0 1-.949-.4 1.393 1.393 0 0 1-.329-.9 2.86 2.86 0 0 1 1.19-2.013 5.1 5.1 0 0 1 3.485-1.052c1.78 0 2.985.577 3.616 1.732.386.868.56 1.816.509 2.765v5.512c-.02.37.017.74.109 1.1.086.335.398.56.743.537.18.006.358-.029.523-.1.25-.136.489-.292.714-.466v.715a4.474 4.474 0 0 1-.949.88 2.766 2.766 0 0 1-1.566.523 1.527 1.527 0 0 1-1.355-.605 2.718 2.718 0 0 1-.461-1.443c-.555.5-1.153.95-1.786 1.346a4.675 4.675 0 0 1-2.379.743 2.857 2.857 0 0 1-2.049-.831 2.8 2.8 0 0 1-.866-2.112 3.917 3.917 0 0 1 1.99-3.339m5.046-1.554c-.911.285-1.788.672-2.612 1.154-1.32.817-1.98 1.742-1.98 2.777a2.112 2.112 0 0 0 .824 1.843c.348.254.77.389 1.2.385a3.292 3.292 0 0 0 1.738-.505c.495-.243.816-.74.832-1.292l-.002-4.362Z"
        data-name="\u30D1\u30B9 1006"
      />
      <path
        fill="#002076"
        d="M545.423 130.444a3.264 3.264 0 0 0 1.732-.516c.36-.425.524-.981.454-1.533v-13.432a3.474 3.474 0 0 0-.151-1.251 1.107 1.107 0 0 0-1.114-.577c-.14.001-.28.015-.419.04-.151.028-.341.07-.57.125v-.454c1.237-.333 2.727-.773 4.468-1.32a.105.105 0 0 1 .116.083c.019.118.026.238.021.357v16.488c-.077.55.073 1.109.414 1.547a3.2 3.2 0 0 0 1.7.447v.385h-6.654l.003-.389Z"
        data-name="\u30D1\u30B9 1007"
      />
      <path
        fill="#002076"
        d="M486.161 156.842c.381 1.097.948 2.12 1.674 3.025a5.25 5.25 0 0 0 4.159 1.966 3.43 3.43 0 0 0 2.328-.852 2.986 2.986 0 0 0 .98-2.379 3.313 3.313 0 0 0-.987-2.392 17.275 17.275 0 0 0-2.715-1.939l-2.4-1.5a8.638 8.638 0 0 1-1.732-1.389 4.306 4.306 0 0 1-1.2-2.941 4.821 4.821 0 0 1 1.417-3.548 5.076 5.076 0 0 1 3.756-1.417 7.2 7.2 0 0 1 2.314.475c.502.2 1.02.358 1.547.473a.882.882 0 0 0 .722-.246c.153-.208.26-.447.31-.7h.577l.621 6h-.676a6.333 6.333 0 0 0-2.207-3.782 5.192 5.192 0 0 0-3-1.072 3.148 3.148 0 0 0-2.012.679 2.276 2.276 0 0 0-.862 1.892 2.763 2.763 0 0 0 .657 1.855 8.501 8.501 0 0 0 2.048 1.637l2.473 1.526a12.845 12.845 0 0 1 3.273 2.626c.63.818.961 1.828.938 2.86a4.741 4.741 0 0 1-1.67 3.7 6.187 6.187 0 0 1-4.269 1.485 8.259 8.259 0 0 1-2.726-.5 10.525 10.525 0 0 0-1.63-.495.7.7 0 0 0-.667.3 1.774 1.774 0 0 0-.227.646h-.621l-.836-5.994.643.001Z"
        data-name="\u30D1\u30B9 1008"
      />
      <path
        fill="#002076"
        d="M500.141 161.984a3.363 3.363 0 0 0 2.24-.79 5.15 5.15 0 0 0 .5-2.837v-12.405l-.385-.452a3.101 3.101 0 0 0-1.052-.9 4.11 4.11 0 0 0-1.3-.2v-.523h4.803l10.869 13.472v-9.357c0-1.538-.229-2.538-.686-3a3.476 3.476 0 0 0-2.008-.588v-.523h6.614v.523a3.272 3.272 0 0 0-2.145.783 4.836 4.836 0 0 0-.537 2.833v14.82h-.48l-12.459-15.443v10.96c0 1.538.225 2.543.674 3.008a3.42 3.42 0 0 0 1.966.619v.523h-6.614v-.523Z"
        data-name="\u30D1\u30B9 1009"
      />
      <path
        fill="#002076"
        d="M521.837 156.842c.382 1.096.948 2.12 1.674 3.025a5.251 5.251 0 0 0 4.16 1.966 3.435 3.435 0 0 0 2.328-.852 2.986 2.986 0 0 0 .98-2.379 3.32 3.32 0 0 0-.987-2.392 17.326 17.326 0 0 0-2.717-1.939l-2.4-1.5a8.537 8.537 0 0 1-1.732-1.389 4.306 4.306 0 0 1-1.2-2.941 4.814 4.814 0 0 1 1.417-3.548 5.075 5.075 0 0 1 3.754-1.417c.795.029 1.58.189 2.323.474.502.2 1.02.358 1.547.473.266.031.53-.06.722-.246.153-.208.259-.447.31-.7h.577l.62 6h-.676a6.333 6.333 0 0 0-2.207-3.782 5.2 5.2 0 0 0-3-1.072 3.153 3.153 0 0 0-2.013.679 2.279 2.279 0 0 0-.861 1.892 2.763 2.763 0 0 0 .658 1.855 8.527 8.527 0 0 0 2.047 1.637l2.474 1.526a12.906 12.906 0 0 1 3.273 2.626c.63.818.961 1.828.938 2.86a4.747 4.747 0 0 1-1.67 3.7 6.19 6.19 0 0 1-4.269 1.485 8.268 8.268 0 0 1-2.728-.5 10.475 10.475 0 0 0-1.63-.495.7.7 0 0 0-.667.3 1.84 1.84 0 0 0-.227.646h-.62l-.838-5.994.64.002Z"
        data-name="\u30D1\u30B9 1010"
      />
      <path
        fill="#002076"
        d="M504.457 55.101a28.887 28.887 0 0 1 3.038 13.473 33.658 33.658 0 0 1-1.865 11.277c-2.344 6.638-6.404 9.957-12.18 9.956-3.95 0-7.199-1.794-9.744-5.383-2.728-3.83-4.092-9.032-4.092-15.608a30.1 30.1 0 0 1 2.737-13.355c2.467-5.074 6.256-7.61 11.367-7.61 4.613 0 8.192 2.416 10.739 7.249m-4.573 28.155c1.102-3.226 1.653-7.893 1.652-14a54.636 54.636 0 0 0-.991-11.661c-1.244-5.408-3.57-8.112-6.978-8.113-3.408 0-5.743 2.784-7.006 8.354a55.621 55.621 0 0 0-.994 11.721 46.678 46.678 0 0 0 1.024 10.905c1.283 5.09 3.689 7.634 7.219 7.633 2.948 0 4.973-1.613 6.075-4.838"
        data-name="\u30D1\u30B9 1011"
      />
      <path
        fill="#002076"
        d="M511.994 88.546c7.339-7.615 12.321-13.205 14.947-16.77 2.626-3.565 3.94-7.041 3.94-10.427 0-2.965-.802-5.224-2.406-6.777a7.976 7.976 0 0 0-5.744-2.328 8.342 8.342 0 0 0-6.677 3 17.392 17.392 0 0 0-2.707 5.079l-1.3-.269c1.005-4.61 2.705-7.812 5.1-9.605a12.627 12.627 0 0 1 7.718-2.686 11.014 11.014 0 0 1 8.226 3.2 10.74 10.74 0 0 1 3.141 7.828 15.694 15.694 0 0 1-3.4 9.494c-2.267 3.046-7.19 8.466-14.772 16.259h13.81a8.567 8.567 0 0 0 4.062-.692 9.032 9.032 0 0 0 2.647-3.365l.781.359-3.339 8.389h-24.027v-.689Z"
        data-name="\u30D1\u30B9 1012"
      />
      <path
        fill="#002076"
        d="M559.808 404.331c.59.187 1.191.339 1.8.454.268 0 .53-.077.757-.22.247-.157.425-.403.495-.688h.591l.262 6.325h-.646a9.165 9.165 0 0 0-1.471-3.039 5.653 5.653 0 0 0-4.662-2.172 5.916 5.916 0 0 0-4.833 2.337 10.01 10.01 0 0 0-1.876 6.434 8.8 8.8 0 0 0 1.973 6.015 6.36 6.36 0 0 0 5 2.249c1.411.01 2.8-.35 4.028-1.045.844-.5 1.624-1.1 2.323-1.788l.523.523a9.476 9.476 0 0 1-2.323 2.077 10 10 0 0 1-12.333-1.389 9.522 9.522 0 0 1-2.462-6.7 9.753 9.753 0 0 1 2.6-6.9 9.3 9.3 0 0 1 7.122-2.928 11.51 11.51 0 0 1 3.134.454"
        data-name="\u30D1\u30B9 1013"
      />
      <path
        fill="#002076"
        d="M570.569 410.105v8.8c-.02.513.069 1.024.26 1.5a1.892 1.892 0 0 0 1.871 1.044 3.185 3.185 0 0 0 1.828-.618 5.4 5.4 0 0 0 1.031-.949v-7.536c.07-.5-.08-1.007-.412-1.389a3.134 3.134 0 0 0-1.651-.4v-.454h4.44v9.679c-.05.455.07.913.338 1.285.415.285.926.396 1.422.31v.385c-.503.137-.874.24-1.112.31-.239.069-.639.194-1.2.378-.239.081-.762.28-1.568.591a.072.072 0 0 1-.08-.062l-.001-.001a.676.676 0 0 1-.014-.144v-2.212a9.365 9.365 0 0 1-1.705 1.649 4.209 4.209 0 0 1-2.474.825 3.537 3.537 0 0 1-2.256-.855 3.371 3.371 0 0 1-1.072-2.821v-7.148a1.8 1.8 0 0 0-.468-1.461 2.842 2.842 0 0 0-1.292-.326v-.385l4.115.005Z"
        data-name="\u30D1\u30B9 1014"
      />
      <path
        fill="#002076"
        d="M582.616 418.505h.454c.137.847.425 1.661.85 2.406a3.112 3.112 0 0 0 2.812 1.54 2.26 2.26 0 0 0 1.626-.57c.4-.385.619-.922.6-1.478a2.039 2.039 0 0 0-.345-1.114 3.505 3.505 0 0 0-1.209-1.044l-1.54-.88a8.807 8.807 0 0 1-2.488-1.828 3.216 3.216 0 0 1-.8-2.173 3.249 3.249 0 0 1 1.1-2.529 3.977 3.977 0 0 1 2.763-.991c.544.01 1.084.103 1.6.275.321.115.65.207.984.276a.705.705 0 0 0 .357-.069.584.584 0 0 0 .192-.22h.331l.1 3.835h-.426a6.347 6.347 0 0 0-.743-2.075 2.729 2.729 0 0 0-2.46-1.376 1.952 1.952 0 0 0-2.062 1.98c0 .845.633 1.597 1.9 2.256l1.814.975c1.953 1.067 2.93 2.304 2.928 3.713a3.32 3.32 0 0 1-1.214 2.652 4.738 4.738 0 0 1-3.178 1.031 7.346 7.346 0 0 1-1.865-.275 8.468 8.468 0 0 0-1.234-.275.411.411 0 0 0-.29.116.835.835 0 0 0-.192.282h-.357l-.008-4.44Z"
        data-name="\u30D1\u30B9 1015"
      />
      <path
        fill="#002076"
        d="M599.892 410.16v.989h-2.805l-.026 7.919a5.35 5.35 0 0 0 .178 1.582c.152.582.692.979 1.293.949a1.55 1.55 0 0 0 .859-.234c.307-.212.586-.462.831-.743l.359.3-.3.414a4.412 4.412 0 0 1-1.513 1.36 3.43 3.43 0 0 1-1.54.4 2.168 2.168 0 0 1-2.2-1.445 5.971 5.971 0 0 1-.315-2.172v-8.333h-1.5a.367.367 0 0 1-.1-.081.17.17 0 0 1-.035-.111.33.33 0 0 1 .062-.213c.124-.124.255-.241.392-.35.488-.388.945-.813 1.367-1.272.28-.324.938-1.183 1.973-2.578a.434.434 0 0 1 .213.028c.023.019.035.088.035.206v3.382l2.772.003Z"
        data-name="\u30D1\u30B9 1016"
      />
      <path
        fill="#002076"
        d="M603.991 411.83a5.808 5.808 0 0 1 4.475-1.89 6.11 6.11 0 0 1 4.509 1.788 6.427 6.427 0 0 1 1.788 4.757 7.082 7.082 0 0 1-1.727 4.757 5.633 5.633 0 0 1-4.482 2.02 5.907 5.907 0 0 1-4.468-1.938 6.814 6.814 0 0 1-1.828-4.868 6.582 6.582 0 0 1 1.739-4.627m2.2-.35c-.919.834-1.378 2.273-1.378 4.317a9.7 9.7 0 0 0 1.109 4.565 3.394 3.394 0 0 0 3.078 2.117 2.618 2.618 0 0 0 2.376-1.417 7.254 7.254 0 0 0 .834-3.712 9.818 9.818 0 0 0-1.061-4.482 3.324 3.324 0 0 0-3.071-2.1 2.73 2.73 0 0 0-1.887.714"
        data-name="\u30D1\u30B9 1017"
      />
      <path
        fill="#002076"
        d="M616.783 422.479a3.126 3.126 0 0 0 1.429-.357c.426-.414.63-1.005.551-1.594v-7.108a2.245 2.245 0 0 0-.267-1.334 1.106 1.106 0 0 0-.887-.317 2.377 2.377 0 0 0-.433.028 2.196 2.196 0 0 0-.338.1v-.48l.991-.331c.357-.118.944-.333 1.76-.646.817-.311 1.246-.466 1.292-.466a.075.075 0 0 1 .083.066v.003c.012.086.016.173.014.26v1.857c.7-.677 1.492-1.253 2.351-1.712a4.647 4.647 0 0 1 2.034-.473 2.836 2.836 0 0 1 2.254.963c.332.42.58.9.729 1.415a11.66 11.66 0 0 1 1.774-1.526 4.827 4.827 0 0 1 2.668-.852 2.88 2.88 0 0 1 2.955 1.8 8.9 8.9 0 0 1 .428 3.217v5.705c-.06.485.099.97.433 1.327a3.053 3.053 0 0 0 1.559.461v.357h-6.2v-.385a2.718 2.718 0 0 0 1.575-.48c.308-.44.442-.979.377-1.512v-5.927a4.985 4.985 0 0 0-.289-1.966 1.968 1.968 0 0 0-1.992-1.126 3.153 3.153 0 0 0-1.774.591c-.452.325-.87.693-1.251 1.1v7.039c-.038.59.098 1.176.391 1.689a1.984 1.984 0 0 0 1.658.619v.357h-6.311v-.357a2.523 2.523 0 0 0 1.663-.632 4.545 4.545 0 0 0 .357-2.311v-3.842a10.23 10.23 0 0 0-.275-2.909 1.844 1.844 0 0 0-1.925-1.346c-.58.005-1.15.163-1.649.459-.545.31-1.03.717-1.431 1.2v7.52c-.052.51.077 1.021.364 1.445.459.332 1.026.48 1.589.412v.357h-6.256l-.001-.358Z"
        data-name="\u30D1\u30B9 1018"
      />
      <path
        fill="#002076"
        d="M648.975 411.281a4.9 4.9 0 0 1 1.457 3.8h-8.735a7.3 7.3 0 0 0 1.445 4.635 4.031 4.031 0 0 0 3.094 1.455 3.884 3.884 0 0 0 2.432-.755 7.575 7.575 0 0 0 1.828-2.138l.482.164a7.856 7.856 0 0 1-2.11 3.238 5.268 5.268 0 0 1-3.789 1.484 4.634 4.634 0 0 1-3.993-1.95 7.481 7.481 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.242 5.242 0 0 1 4.261-2.006 4.858 4.858 0 0 1 3.4 1.341m-6.585 1.051a5.454 5.454 0 0 0-.632 1.871h5.8a5.752 5.752 0 0 0-.523-2.048 2.372 2.372 0 0 0-2.256-1.209 2.629 2.629 0 0 0-2.392 1.387"
        data-name="\u30D1\u30B9 1019"
      />
      <path
        fill="#002076"
        d="M652.651 422.382a3.325 3.325 0 0 0 1.677-.447c.334-.399.484-.92.412-1.436v-6.2a5.268 5.268 0 0 0-.253-1.943.926.926 0 0 0-.927-.584 3.102 3.102 0 0 0-.364.032c-.151.023-.31.056-.475.09v-.454l1.6-.561c.546-.19.926-.329 1.139-.414a24.65 24.65 0 0 0 1.41-.591c.067 0 .1.021.118.069.018.096.025.193.021.29v2.253a10.554 10.554 0 0 1 1.7-1.911 2.621 2.621 0 0 1 1.7-.686 1.6 1.6 0 0 1 1.144.419c.543.542.586 1.407.1 2-.21.249-.52.39-.845.384a1.584 1.584 0 0 1-1.052-.479 1.485 1.485 0 0 0-.832-.484c-.32 0-.715.26-1.183.778a2.388 2.388 0 0 0-.7 1.6v6.2a2.052 2.052 0 0 0 .551 1.646 2.76 2.76 0 0 0 1.828.431v.456h-6.779l.01-.458Z"
        data-name="\u30D1\u30B9 1020"
      />
      <path
        fill="#002076"
        d="M552.789 453.992a3.212 3.212 0 0 0 2.022-.63 3.965 3.965 0 0 0 .4-2.23v-12.2a2.937 2.937 0 0 0-.447-1.943 3.244 3.244 0 0 0-1.973-.584v-.523h7.8c1.378-.04 2.752.17 4.056.618a4.285 4.285 0 0 1 3.08 4.3 3.935 3.935 0 0 1-1.369 3.224 7.49 7.49 0 0 1-3.719 1.484l5.8 7.145c.294.383.658.707 1.072.956.427.22.895.35 1.374.384v.523h-4.535l-7.043-8.678-1.235.069v5.549c0 1.043.158 1.703.475 1.98a3.9 3.9 0 0 0 2.07.558v.523h-7.828v-.525Zm9.625-9.764a3.418 3.418 0 0 0 2.24-3.458 3.314 3.314 0 0 0-2.006-3.333 7.044 7.044 0 0 0-2.846-.482 3.5 3.5 0 0 0-1.464.174c-.179.111-.269.38-.269.8v6.932c1.472.015 2.938-.2 4.345-.634"
        data-name="\u30D1\u30B9 1021"
      />
      <path
        fill="#002076"
        d="M580.43 442.958a4.9 4.9 0 0 1 1.457 3.8h-8.731a7.3 7.3 0 0 0 1.445 4.637 4.031 4.031 0 0 0 3.094 1.455 3.883 3.883 0 0 0 2.432-.755 7.577 7.577 0 0 0 1.828-2.138l.482.164a7.855 7.855 0 0 1-2.11 3.238 5.268 5.268 0 0 1-3.789 1.484 4.634 4.634 0 0 1-3.993-1.95 7.481 7.481 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.242 5.242 0 0 1 4.261-2.006 4.858 4.858 0 0 1 3.4 1.341m-6.585 1.051a5.456 5.456 0 0 0-.632 1.871h5.8a5.75 5.75 0 0 0-.523-2.048 2.372 2.372 0 0 0-2.256-1.209 2.629 2.629 0 0 0-2.392 1.387"
        data-name="\u30D1\u30B9 1022"
      />
      <path
        fill="#002076"
        d="M582.971 454.128a3.248 3.248 0 0 0 1.732-.516c.36-.424.524-.98.454-1.531v-13.434a3.488 3.488 0 0 0-.151-1.251 1.11 1.11 0 0 0-1.114-.577c-.14.002-.28.016-.419.042a8.913 8.913 0 0 0-.57.125v-.456c1.237-.33 2.727-.77 4.468-1.32a.1.1 0 0 1 .116.081v.003c.019.117.026.236.021.355v16.485c-.077.55.073 1.108.414 1.547a3.18 3.18 0 0 0 1.7.447v.387h-6.651v-.387Z"
        data-name="\u30D1\u30B9 1023"
      />
      <path
        fill="#002076"
        d="M592.842 448.451c1.61-.9 3.299-1.651 5.046-2.242v-1.169a4.816 4.816 0 0 0-.275-1.952 1.96 1.96 0 0 0-1.939-.922c-.468 0-.929.124-1.334.359a1.135 1.135 0 0 0-.632 1.017c.009.187.032.373.069.556.034.165.057.332.069.5.043.425-.153.838-.509 1.074a1.287 1.287 0 0 1-.688.178 1.156 1.156 0 0 1-.949-.4 1.393 1.393 0 0 1-.329-.9 2.86 2.86 0 0 1 1.19-2.013 5.1 5.1 0 0 1 3.484-1.052c1.78 0 2.985.577 3.616 1.732.386.868.56 1.816.509 2.765v5.512c-.02.37.017.74.109 1.1.086.335.398.56.743.537.18.006.358-.029.523-.1.25-.136.489-.292.714-.466v.715a4.474 4.474 0 0 1-.949.88 2.766 2.766 0 0 1-1.566.523 1.527 1.527 0 0 1-1.355-.605 2.718 2.718 0 0 1-.461-1.443c-.555.5-1.153.95-1.786 1.346a4.675 4.675 0 0 1-2.379.743 2.858 2.858 0 0 1-2.049-.831 2.8 2.8 0 0 1-.866-2.112 3.917 3.917 0 0 1 1.994-3.326m5.046-1.554c-.911.285-1.788.672-2.612 1.154-1.32.817-1.98 1.742-1.98 2.777a2.112 2.112 0 0 0 .824 1.843c.348.254.769.389 1.2.385a3.293 3.293 0 0 0 1.739-.509c.495-.243.816-.74.832-1.292l-.003-4.358Z"
        data-name="\u30D1\u30B9 1024"
      />
      <path
        fill="#002076"
        d="M609.182 441.838v.989h-2.805l-.026 7.919a5.35 5.35 0 0 0 .178 1.582c.152.582.692.979 1.293.949.303.008.602-.074.859-.234.307-.212.586-.462.831-.743l.359.3-.3.414a4.412 4.412 0 0 1-1.513 1.36 3.43 3.43 0 0 1-1.54.4 2.169 2.169 0 0 1-2.2-1.445 5.971 5.971 0 0 1-.315-2.172v-8.333h-1.5a.367.367 0 0 1-.1-.081.17.17 0 0 1-.035-.111.33.33 0 0 1 .062-.213 4.47 4.47 0 0 1 .392-.35c.488-.388.945-.813 1.367-1.272.28-.324.938-1.183 1.973-2.578a.433.433 0 0 1 .213.028c.023.019.035.088.035.206v3.382l2.772.003Z"
        data-name="\u30D1\u30B9 1025"
      />
      <path
        fill="#002076"
        d="M610.485 454.129a3.5 3.5 0 0 0 1.857-.5c.255-.243.383-.9.384-1.973v-6.559a4.167 4.167 0 0 0-.123-1.237.829.829 0 0 0-.866-.563c-.1 0-.201.008-.3.028-.1.018-.373.09-.832.22v-.428l.591-.192c1.6-.523 2.718-.917 3.354-1.183.158-.079.326-.134.5-.165a.79.79 0 0 1 .026.208v9.871a3.213 3.213 0 0 0 .378 1.966c.5.35 1.101.53 1.712.509v.385h-6.682l.001-.387Zm2.186-18.383a1.36 1.36 0 0 1 1.017-.424c.79 0 1.43.64 1.431 1.429v.007c.005.377-.148.74-.421 1a1.425 1.425 0 0 1-2.439-1 1.4 1.4 0 0 1 .412-1.012"
        data-name="\u30D1\u30B9 1026"
      />
      <path
        fill="#002076"
        d="M619.633 443.507a5.809 5.809 0 0 1 4.475-1.89 6.11 6.11 0 0 1 4.509 1.788 6.427 6.427 0 0 1 1.788 4.757 7.082 7.082 0 0 1-1.732 4.757 5.633 5.633 0 0 1-4.482 2.02 5.907 5.907 0 0 1-4.468-1.938 6.814 6.814 0 0 1-1.828-4.868 6.582 6.582 0 0 1 1.739-4.627m2.2-.35c-.919.834-1.378 2.273-1.378 4.317a9.701 9.701 0 0 0 1.109 4.565 3.394 3.394 0 0 0 3.078 2.117 2.618 2.618 0 0 0 2.376-1.417 7.254 7.254 0 0 0 .834-3.712 9.819 9.819 0 0 0-1.063-4.481 3.324 3.324 0 0 0-3.071-2.1 2.73 2.73 0 0 0-1.887.714"
        data-name="\u30D1\u30B9 1027"
      />
      <path
        fill="#002076"
        d="M631.354 454.128c.494-.023.97-.193 1.367-.487a2.319 2.319 0 0 0 .392-1.628v-6.987a3.167 3.167 0 0 0-.165-1.225 1.082 1.082 0 0 0-1.072-.519c-.085 0-.17.004-.255.014-.086.009-.194.019-.322.039v-.48a134.64 134.64 0 0 0 2.666-.854l1.294-.428c.063 0 .102.023.116.069a.716.716 0 0 1 .021.194v2.02c.608-.6 1.27-1.143 1.976-1.623a4.128 4.128 0 0 1 2.224-.672 2.917 2.917 0 0 1 1.7.535c.987.699 1.481 1.94 1.482 3.726v6.423a2.1 2.1 0 0 0 .4 1.427c.357.331.838.496 1.323.454v.387h-5.871v-.387a2.349 2.349 0 0 0 1.4-.556 2.826 2.826 0 0 0 .4-1.821v-5.871a3.955 3.955 0 0 0-.438-1.96 1.686 1.686 0 0 0-1.612-.774 2.99 2.99 0 0 0-1.64.547c-.429.314-.83.663-1.2 1.045v7.7c-.064.487.098.977.44 1.329.415.259.9.385 1.388.363v.387h-6.008l-.006-.387Z"
        data-name="\u30D1\u30B9 1028"
      />
      <path
        fill="#002076"
        d="M646.191 450.183h.454c.137.847.425 1.661.85 2.406a3.112 3.112 0 0 0 2.812 1.54 2.259 2.259 0 0 0 1.626-.57c.4-.385.619-.922.6-1.478a2.039 2.039 0 0 0-.345-1.114 3.505 3.505 0 0 0-1.209-1.044l-1.54-.88a8.803 8.803 0 0 1-2.488-1.829 3.413 3.413 0 0 1 .3-4.7 3.977 3.977 0 0 1 2.763-.991c.544.01 1.084.103 1.6.275.321.115.65.207.984.276a.705.705 0 0 0 .357-.069.584.584 0 0 0 .192-.22h.331l.095 3.835h-.426a6.35 6.35 0 0 0-.743-2.075 2.729 2.729 0 0 0-2.46-1.376 1.952 1.952 0 0 0-2.063 1.98c0 .845.633 1.597 1.9 2.256l1.814.975c1.953 1.067 2.93 2.304 2.928 3.713a3.32 3.32 0 0 1-1.214 2.652 4.738 4.738 0 0 1-3.178 1.031 7.342 7.342 0 0 1-1.865-.275 8.468 8.468 0 0 0-1.234-.275.411.411 0 0 0-.29.116.833.833 0 0 0-.192.282h-.359v-4.441Z"
        data-name="\u30D1\u30B9 1029"
      />
      <path
        fill="#002076"
        d="M598.933 347.933a28.886 28.886 0 0 1 3.038 13.473 33.658 33.658 0 0 1-1.865 11.277c-2.344 6.638-6.404 9.957-12.18 9.956-3.95 0-7.199-1.794-9.744-5.383-2.728-3.83-4.092-9.032-4.092-15.608a30.1 30.1 0 0 1 2.738-13.354c2.467-5.074 6.256-7.61 11.367-7.61 4.613 0 8.192 2.416 10.739 7.249m-4.572 28.154c1.102-3.226 1.653-7.893 1.653-14a54.636 54.636 0 0 0-.991-11.661c-1.244-5.408-3.57-8.112-6.978-8.113-3.408 0-5.743 2.784-7.006 8.354a55.62 55.62 0 0 0-.994 11.721 46.674 46.674 0 0 0 1.023 10.909c1.283 5.09 3.689 7.633 7.219 7.632 2.948 0 4.973-1.613 6.075-4.838"
        data-name="\u30D1\u30B9 1030"
      />
      <path
        fill="#002076"
        d="M612.964 378.568a9.13 9.13 0 0 0 4.616 1.73 7.194 7.194 0 0 0 6.256-2.847 10.4 10.4 0 0 0 2.045-6.189 9.845 9.845 0 0 0-1.656-5.663c-1.862-2.753-5.01-4.129-9.442-4.129-.26 0-.512.007-.753.016s-.521.035-.841.076l-.06-.781a26.014 26.014 0 0 0 7.578-4 7.3 7.3 0 0 0 2.768-5.954 5.973 5.973 0 0 0-2.182-4.994 7.844 7.844 0 0 0-4.977-1.712 8.16 8.16 0 0 0-5.834 2.436 18.66 18.66 0 0 0-2.979 4.09l-.931-.209c.68-2.85 2.25-5.41 4.482-7.309a11.472 11.472 0 0 1 7.58-2.737c3.067 0 5.437.842 7.112 2.525a7.992 7.992 0 0 1 .436 11.189 19.31 19.31 0 0 1-3.641 3.1 15.528 15.528 0 0 1 4.634 2.888 10.634 10.634 0 0 1 3.279 8 13.646 13.646 0 0 1-4.5 10.077c-3 2.907-7.267 4.36-12.8 4.359-2.467 0-4.195-.367-5.186-1.1a3.024 3.024 0 0 1-1.489-2.362c.008-.54.182-1.064.5-1.5a1.81 1.81 0 0 1 1.609-.721 9.008 9.008 0 0 1 4.376 1.721"
        data-name="\u30D1\u30B9 1031"
      />
      <path
        fill="#002076"
        d="M323.2 596.761a4.066 4.066 0 0 0 2.2-.526 2.761 2.761 0 0 0 .5-2v-12.529a2.788 2.788 0 0 0-.5-2 3.89 3.89 0 0 0-2.2-.528v-.523h7.884a11.2 11.2 0 0 1 5.364 1.088 3.905 3.905 0 0 1 1.985 3.741 3.536 3.536 0 0 1-1.389 3.029 6.562 6.562 0 0 1-2.351.977 8.417 8.417 0 0 1 3.314 1.559 3.684 3.684 0 0 1 1.374 3.055 4.513 4.513 0 0 1-3.048 4.533 10.607 10.607 0 0 1-3.956.648h-9.178l.001-.524Zm10.586-10.214a2.988 2.988 0 0 0 1.788-2.962 3.352 3.352 0 0 0-2.063-3.4 9.424 9.424 0 0 0-3.465-.482 2.328 2.328 0 0 0-1.086.151c-.137.1-.206.377-.206.827v6.364l2.68-.04a5.99 5.99 0 0 0 2.351-.454m.908 8.882c.971-.558 1.457-1.63 1.457-3.217a3.285 3.285 0 0 0-1.705-3.231 9.562 9.562 0 0 0-4.343-.866h-.818c-.116 0-.29-.005-.53-.014v7.011c0 .487.137.8.412.942a5.143 5.143 0 0 0 1.911.215 7.169 7.169 0 0 0 3.617-.839"
        data-name="\u30D1\u30B9 1032"
      />
      <path
        fill="#002076"
        d="M341.469 596.828a3.325 3.325 0 0 0 1.677-.447c.334-.4.483-.92.412-1.436v-6.2a5.283 5.283 0 0 0-.253-1.945.926.926 0 0 0-.927-.584 2.81 2.81 0 0 0-.364.033 8.48 8.48 0 0 0-.475.09v-.454l1.6-.563c.549-.192.929-.331 1.139-.412a24.65 24.65 0 0 0 1.41-.591c.067 0 .1.021.118.069.018.095.026.192.021.289v2.254a10.49 10.49 0 0 1 1.7-1.911 2.623 2.623 0 0 1 1.7-.688c.422-.017.834.134 1.144.421.544.541.587 1.407.1 2-.209.25-.52.39-.845.385a1.579 1.579 0 0 1-1.052-.48 1.48 1.48 0 0 0-.832-.482c-.32 0-.715.26-1.183.778a2.384 2.384 0 0 0-.7 1.6v6.2a2.05 2.05 0 0 0 .551 1.644c.54.355 1.187.508 1.828.433v.454h-6.779l.01-.457Z"
        data-name="\u30D1\u30B9 1033"
      />
      <path
        fill="#002076"
        d="M354.262 591.22c1.61-.9 3.299-1.651 5.046-2.242v-1.169a4.817 4.817 0 0 0-.275-1.952 1.96 1.96 0 0 0-1.939-.922c-.469 0-.929.124-1.334.359a1.135 1.135 0 0 0-.632 1.017c.009.187.032.373.069.556.034.165.057.332.069.5.043.425-.153.838-.509 1.073a1.287 1.287 0 0 1-.688.178 1.156 1.156 0 0 1-.949-.4 1.393 1.393 0 0 1-.329-.9 2.86 2.86 0 0 1 1.19-2.013 5.1 5.1 0 0 1 3.485-1.052c1.78 0 2.985.577 3.616 1.732.386.868.56 1.816.509 2.765v5.512c-.02.37.017.74.109 1.1.086.335.398.56.743.537.18.006.358-.029.523-.1.25-.136.489-.292.714-.466v.715a4.474 4.474 0 0 1-.949.88 2.766 2.766 0 0 1-1.566.523 1.527 1.527 0 0 1-1.355-.605 2.718 2.718 0 0 1-.461-1.443c-.555.5-1.153.95-1.786 1.346a4.675 4.675 0 0 1-2.379.743 2.858 2.858 0 0 1-2.048-.831 2.8 2.8 0 0 1-.866-2.112 3.917 3.917 0 0 1 1.994-3.326m5.046-1.554c-.911.285-1.788.672-2.612 1.154-1.32.817-1.98 1.742-1.98 2.777a2.112 2.112 0 0 0 .824 1.843c.348.254.769.389 1.2.385a3.293 3.293 0 0 0 1.739-.509c.495-.243.815-.74.832-1.292l-.003-4.358Z"
        data-name="\u30D1\u30B9 1034"
      />
      <path
        fill="#002076"
        d="M365.103 596.898a2.5 2.5 0 0 0 1.367-.487c.33-.476.469-1.057.392-1.63v-6.983a3.177 3.177 0 0 0-.165-1.227 1.082 1.082 0 0 0-1.072-.519c-.083 0-.167.005-.255.014s-.194.021-.322.04v-.48c.377-.11 1.265-.395 2.666-.854l1.293-.426c.067 0 .105.023.116.069a.7.7 0 0 1 .021.194v2.02a14.03 14.03 0 0 1 1.976-1.623 4.116 4.116 0 0 1 2.224-.674 2.906 2.906 0 0 1 1.7.537c.987.697 1.481 1.939 1.482 3.726v6.422c-.044.51.098 1.017.4 1.429.358.33.838.494 1.323.454v.385h-5.871v-.387a2.36 2.36 0 0 0 1.4-.556 2.832 2.832 0 0 0 .4-1.823v-5.869a3.946 3.946 0 0 0-.438-1.96 1.684 1.684 0 0 0-1.612-.776 2.98 2.98 0 0 0-1.64.549c-.429.314-.83.663-1.2 1.045v7.7a1.6 1.6 0 0 0 .44 1.327c.415.26.9.386 1.389.364v.385h-6.008l-.006-.386Z"
        data-name="\u30D1\u30B9 1035"
      />
      <path
        fill="#002076"
        d="M387.361 584.731a4.4 4.4 0 0 1 1.059.8v-4.372c.03-.395-.033-.79-.185-1.156-.125-.211-.421-.315-.887-.315-.111 0-.208.005-.289.012l-.551.056v-.454l1.128-.289c.412-.11.824-.225 1.237-.343.413-.118.775-.233 1.086-.345.146-.046.391-.132.729-.26l.083.026-.028 1.445-.026 1.616c-.008.557-.014 1.1-.014 1.642l-.028 11.233c-.033.429.043.86.22 1.251.146.239.537.357 1.169.357.1 0 .2 0 .3-.007.1-.005.2-.016.3-.033v.454c-.055.017-.72.246-1.994.686l-2.158.811-.1-.123v-1.691a7.262 7.262 0 0 1-1.334 1.2 4.34 4.34 0 0 1-2.323.618 4.626 4.626 0 0 1-3.787-1.807 6.494 6.494 0 0 1-1.452-4.187 7.846 7.846 0 0 1 1.741-5.121 5.3 5.3 0 0 1 4.255-2.138 3.893 3.893 0 0 1 1.841.44m.4 10.7c.374-.294.612-.727.66-1.2v-5.9a3 3 0 0 0-.956-2.524 3.083 3.083 0 0 0-1.864-.736 3.017 3.017 0 0 0-2.694 1.533 6.978 6.978 0 0 0-.963 3.775 7.618 7.618 0 0 0 1.024 3.946 3.334 3.334 0 0 0 3.06 1.732 2.4 2.4 0 0 0 1.732-.632"
        data-name="\u30D1\u30B9 1036"
      />
      <path
        fill="#002076"
        d="M282.22 628.437a4.16 4.16 0 0 0 2.008-.554c.311-.259.467-.917.468-1.976v-12.525a2.924 2.924 0 0 0-.454-1.98 3.481 3.481 0 0 0-2.022-.549v-.523h7.59c2.23-.067 4.447.365 6.489 1.265 3.19 1.512 4.786 4.216 4.785 8.111a9.594 9.594 0 0 1-.859 4 8.211 8.211 0 0 1-2.592 3.2 8.78 8.78 0 0 1-2.283 1.251 15.452 15.452 0 0 1-5.306.8h-7.824v-.52Zm5.672-.743c.441.188.922.263 1.4.22a10.249 10.249 0 0 0 5.431-1.308c2.18-1.34 3.271-3.628 3.272-6.867 0-2.888-.898-5.062-2.694-6.522a9.425 9.425 0 0 0-6.119-1.871 2.728 2.728 0 0 0-1.285.206c-.225.137-.336.44-.336.908v14.325c-.049.34.078.682.336.908"
        data-name="\u30D1\u30B9 1037"
      />
      <path
        fill="#002076"
        d="M312.53 617.399a4.907 4.907 0 0 1 1.457 3.8h-8.731a7.31 7.31 0 0 0 1.445 4.637 4.03 4.03 0 0 0 3.094 1.454 3.893 3.893 0 0 0 2.432-.753 7.585 7.585 0 0 0 1.828-2.14l.482.165a7.85 7.85 0 0 1-2.11 3.236 5.262 5.262 0 0 1-3.789 1.485 4.636 4.636 0 0 1-3.993-1.952 7.48 7.48 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.237 5.237 0 0 1 4.261-2.006 4.864 4.864 0 0 1 3.4 1.339m-6.585 1.052a5.46 5.46 0 0 0-.632 1.869h5.8a5.763 5.763 0 0 0-.523-2.048 2.375 2.375 0 0 0-2.256-1.209 2.627 2.627 0 0 0-2.392 1.388"
        data-name="\u30D1\u30B9 1038"
      />
      <path
        fill="#002076"
        d="M320.847 616.187v.426a3.599 3.599 0 0 0-1.01.22.594.594 0 0 0-.3.605c0 .142.02.283.062.419.04.132.111.329.213.584l2.888 6.907 2.446-6.1c.12-.3.232-.618.338-.949.088-.244.14-.498.158-.757a.8.8 0 0 0-.509-.769 2.851 2.851 0 0 0-.825-.165v-.426h3.9v.426a1.28 1.28 0 0 0-.968.447 9.445 9.445 0 0 0-.861 1.807l-3.972 9.9c-.049.125-.11.245-.18.359a.222.222 0 0 1-.192.1.275.275 0 0 1-.234-.158 4.234 4.234 0 0 1-.206-.391l-4.208-10.065a4.006 4.006 0 0 0-1.086-1.663 2.172 2.172 0 0 0-1-.331v-.426h5.546Z"
        data-name="\u30D1\u30B9 1039"
      />
      <path
        fill="#002076"
        d="M338.187 617.399a4.907 4.907 0 0 1 1.457 3.8h-8.731a7.31 7.31 0 0 0 1.445 4.637 4.03 4.03 0 0 0 3.094 1.454 3.893 3.893 0 0 0 2.432-.753 7.585 7.585 0 0 0 1.828-2.14l.482.165a7.85 7.85 0 0 1-2.11 3.236 5.262 5.262 0 0 1-3.789 1.485 4.636 4.636 0 0 1-3.993-1.952 7.48 7.48 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.237 5.237 0 0 1 4.261-2.006 4.864 4.864 0 0 1 3.4 1.339m-6.585 1.052a5.46 5.46 0 0 0-.632 1.869h5.8a5.763 5.763 0 0 0-.523-2.048 2.375 2.375 0 0 0-2.256-1.209 2.627 2.627 0 0 0-2.392 1.388"
        data-name="\u30D1\u30B9 1040"
      />
      <path
        fill="#002076"
        d="M341.292 628.574a3.263 3.263 0 0 0 1.732-.516c.36-.425.524-.981.454-1.533v-13.432a3.477 3.477 0 0 0-.151-1.251 1.107 1.107 0 0 0-1.114-.577c-.14.001-.28.015-.419.04-.151.028-.341.07-.57.125v-.454c1.237-.333 2.727-.773 4.468-1.32a.105.105 0 0 1 .116.083c.019.118.026.238.021.357v16.485c-.077.55.073 1.108.414 1.547a3.2 3.2 0 0 0 1.7.447v.385h-6.654l.003-.386Z"
        data-name="\u30D1\u30B9 1041"
      />
      <path
        fill="#002076"
        d="M350.888 617.953a5.809 5.809 0 0 1 4.475-1.89 6.11 6.11 0 0 1 4.509 1.788 6.427 6.427 0 0 1 1.788 4.757 7.082 7.082 0 0 1-1.732 4.757 5.633 5.633 0 0 1-4.482 2.02 5.907 5.907 0 0 1-4.468-1.938 6.814 6.814 0 0 1-1.828-4.868 6.582 6.582 0 0 1 1.739-4.627m2.2-.35c-.919.834-1.378 2.273-1.378 4.317a9.7 9.7 0 0 0 1.109 4.565 3.394 3.394 0 0 0 3.078 2.117 2.618 2.618 0 0 0 2.376-1.417 7.254 7.254 0 0 0 .834-3.712 9.816 9.816 0 0 0-1.061-4.482 3.324 3.324 0 0 0-3.071-2.1 2.73 2.73 0 0 0-1.887.715"
        data-name="\u30D1\u30B9 1042"
      />
      <path
        fill="#002076"
        d="M362.885 634.638a2.214 2.214 0 0 0 1.631-.551c.267-.442.388-.956.348-1.471v-13.2a2.28 2.28 0 0 0-.255-1.339 1.22 1.22 0 0 0-.956-.3c-.094 0-.188.005-.282.014a4.561 4.561 0 0 0-.378.055v-.454l1.308-.426c.046-.009.967-.325 2.763-.949.042 0 .08.024.1.062a.312.312 0 0 1 .033.144v1.857a10.004 10.004 0 0 1 1.594-1.348 4.754 4.754 0 0 1 2.545-.769 4.148 4.148 0 0 1 3.265 1.614 6.6 6.6 0 0 1 1.367 4.414 8.226 8.226 0 0 1-1.642 4.986 4.945 4.945 0 0 1-4.132 2.24 4.087 4.087 0 0 1-1.334-.194 3.96 3.96 0 0 1-1.621-1.058v4.509c0 .927.15 1.494.452 1.705a4.57 4.57 0 0 0 1.98.426v.482h-6.786v-.449Zm5.424-6.826c.499.315 1.08.475 1.67.461a3.063 3.063 0 0 0 2.538-1.477 7 7 0 0 0 1.051-4.113 5.61 5.61 0 0 0-1.093-3.777 3.288 3.288 0 0 0-2.536-1.249 3.03 3.03 0 0 0-1.857.626 1.67 1.67 0 0 0-.838 1.227v6.907a2.759 2.759 0 0 0 1.065 1.394"
        data-name="\u30D1\u30B9 1043"
      />
      <path
        fill="#002076"
        d="M377.544 628.603a3.126 3.126 0 0 0 1.429-.357c.426-.414.63-1.005.551-1.594v-7.108a2.246 2.246 0 0 0-.268-1.334 1.106 1.106 0 0 0-.887-.317 2.386 2.386 0 0 0-.433.028 2.207 2.207 0 0 0-.338.1v-.48l.991-.331c.357-.118.944-.333 1.76-.646.817-.312 1.246-.466 1.292-.466a.075.075 0 0 1 .083.066v.003c.011.086.016.173.014.26v1.857c.7-.677 1.491-1.253 2.35-1.712a4.649 4.649 0 0 1 2.035-.473 2.836 2.836 0 0 1 2.254.963c.332.42.58.9.729 1.415a11.66 11.66 0 0 1 1.774-1.526 4.827 4.827 0 0 1 2.668-.852 2.88 2.88 0 0 1 2.955 1.8 8.9 8.9 0 0 1 .428 3.217v5.696c-.06.485.099.97.433 1.327a3.054 3.054 0 0 0 1.559.46v.358h-6.2v-.385a2.72 2.72 0 0 0 1.575-.48c.308-.44.442-.979.377-1.512v-5.927a4.985 4.985 0 0 0-.29-1.966 1.968 1.968 0 0 0-1.991-1.126 3.154 3.154 0 0 0-1.774.59c-.452.326-.87.694-1.251 1.1v7.04c-.038.589.098 1.176.39 1.689a1.984 1.984 0 0 0 1.659.619v.357h-6.311v-.357a2.523 2.523 0 0 0 1.663-.632 4.545 4.545 0 0 0 .357-2.311v-3.842a10.23 10.23 0 0 0-.275-2.91 1.845 1.845 0 0 0-1.925-1.345 3.3 3.3 0 0 0-1.65.459 5.21 5.21 0 0 0-1.43 1.2v7.52c-.052.51.077 1.02.364 1.445.459.332 1.026.48 1.589.412v.357h-6.256v-.349Z"
        data-name="\u30D1\u30B9 1044"
      />
      <path
        fill="#002076"
        d="M409.171 617.399a4.907 4.907 0 0 1 1.457 3.8h-8.731a7.31 7.31 0 0 0 1.445 4.637 4.029 4.029 0 0 0 3.094 1.454 3.893 3.893 0 0 0 2.432-.753 7.585 7.585 0 0 0 1.829-2.14l.482.165a7.85 7.85 0 0 1-2.11 3.236 5.262 5.262 0 0 1-3.789 1.485 4.636 4.636 0 0 1-3.993-1.952 7.48 7.48 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.237 5.237 0 0 1 4.261-2.006 4.864 4.864 0 0 1 3.4 1.339m-6.585 1.052a5.46 5.46 0 0 0-.632 1.869h5.8a5.763 5.763 0 0 0-.523-2.048 2.375 2.375 0 0 0-2.256-1.209 2.627 2.627 0 0 0-2.392 1.388"
        data-name="\u30D1\u30B9 1045"
      />
      <path
        fill="#002076"
        d="M412.503 628.575c.494-.023.97-.193 1.367-.487a2.32 2.32 0 0 0 .392-1.63v-6.983a3.177 3.177 0 0 0-.165-1.227 1.082 1.082 0 0 0-1.072-.519c-.085 0-.17.005-.255.014a7.323 7.323 0 0 0-.322.04v-.48c.377-.11 1.265-.395 2.666-.854l1.293-.426c.067 0 .105.023.116.069a.7.7 0 0 1 .021.194v2.02c.608-.6 1.27-1.143 1.976-1.623a4.116 4.116 0 0 1 2.224-.674 2.906 2.906 0 0 1 1.7.537c.987.697 1.481 1.939 1.482 3.726v6.422c-.044.51.098 1.017.4 1.429.358.33.838.494 1.323.454v.385h-5.871v-.387a2.36 2.36 0 0 0 1.4-.556 2.83 2.83 0 0 0 .4-1.823v-5.869a3.946 3.946 0 0 0-.438-1.96 1.684 1.684 0 0 0-1.612-.776 2.98 2.98 0 0 0-1.64.549c-.429.314-.83.663-1.2 1.045v7.7a1.6 1.6 0 0 0 .44 1.327c.415.26.9.386 1.389.364v.385h-6.008l-.006-.386Z"
        data-name="\u30D1\u30B9 1046"
      />
      <path
        fill="#002076"
        d="M433.208 616.283v.989h-2.805l-.026 7.919a5.35 5.35 0 0 0 .178 1.582c.152.582.692.979 1.293.949.303.008.602-.074.859-.234.307-.212.586-.462.831-.743l.359.3-.3.414a4.412 4.412 0 0 1-1.513 1.36 3.43 3.43 0 0 1-1.54.4 2.168 2.168 0 0 1-2.2-1.445 5.971 5.971 0 0 1-.315-2.172v-8.329h-1.5a.367.367 0 0 1-.1-.081.17.17 0 0 1-.035-.111.33.33 0 0 1 .062-.213c.124-.124.255-.241.392-.35.488-.388.945-.813 1.367-1.272.28-.324.938-1.183 1.973-2.578a.434.434 0 0 1 .213.028c.023.019.035.088.035.206v3.382l2.772-.001Z"
        data-name="\u30D1\u30B9 1047"
      />
      <path
        fill="#002076"
        d="M351.826 520.431a28.887 28.887 0 0 1 3.038 13.474 33.658 33.658 0 0 1-1.865 11.277c-2.344 6.638-6.404 9.957-12.18 9.956-3.95 0-7.199-1.794-9.744-5.383-2.728-3.83-4.092-9.032-4.092-15.608a30.1 30.1 0 0 1 2.738-13.354c2.467-5.074 6.256-7.61 11.367-7.61 4.613 0 8.192 2.416 10.739 7.249m-4.572 28.154c1.102-3.226 1.653-7.893 1.653-14a54.636 54.636 0 0 0-.991-11.661c-1.244-5.408-3.57-8.112-6.978-8.113-3.408 0-5.743 2.784-7.006 8.354a55.628 55.628 0 0 0-.994 11.721 46.68 46.68 0 0 0 1.022 10.909c1.283 5.09 3.689 7.634 7.219 7.633 2.948 0 4.973-1.613 6.075-4.838"
        data-name="\u30D1\u30B9 1048"
      />
      <path
        fill="#002076"
        d="M378.336 513.244h2.858v27.068h6.284v4h-6.284v10.256h-4.783v-10.257h-17.324v-4l19.249-27.067Zm-1.983 6.209-14.762 20.86h14.762v-20.86Z"
        data-name="\u30D1\u30B9 1049"
      />
      <path
        fill="#002076"
        d="M76.939 422.315a4.064 4.064 0 0 0 2.2-.526 2.761 2.761 0 0 0 .5-2V407.26a2.788 2.788 0 0 0-.5-2 3.89 3.89 0 0 0-2.2-.528v-.523h7.881a11.2 11.2 0 0 1 5.364 1.088 3.905 3.905 0 0 1 1.985 3.741 3.536 3.536 0 0 1-1.389 3.029 6.559 6.559 0 0 1-2.351.977 8.419 8.419 0 0 1 3.314 1.559 3.684 3.684 0 0 1 1.374 3.055 4.513 4.513 0 0 1-3.048 4.533 10.607 10.607 0 0 1-3.956.648h-9.174v-.524Zm10.581-10.216a2.988 2.988 0 0 0 1.788-2.962 3.352 3.352 0 0 0-2.063-3.4 9.424 9.424 0 0 0-3.465-.482 2.33 2.33 0 0 0-1.086.151c-.137.1-.206.377-.206.827v6.364l2.68-.04a5.987 5.987 0 0 0 2.351-.454m.908 8.882c.971-.558 1.457-1.63 1.457-3.217a3.285 3.285 0 0 0-1.705-3.231 9.563 9.563 0 0 0-4.343-.866h-.816c-.116 0-.29-.005-.53-.014v7.011c0 .487.137.8.412.941a5.143 5.143 0 0 0 1.911.215 7.168 7.168 0 0 0 3.617-.839"
        data-name="\u30D1\u30B9 1050"
      />
      <path
        fill="#002076"
        d="M95.208 422.382a3.325 3.325 0 0 0 1.677-.447c.334-.399.484-.92.412-1.436v-6.2a5.268 5.268 0 0 0-.253-1.943.926.926 0 0 0-.927-.584 3.102 3.102 0 0 0-.364.032c-.151.023-.31.056-.475.09v-.454l1.6-.561c.546-.19.926-.329 1.139-.414a24.65 24.65 0 0 0 1.41-.591c.067 0 .1.021.118.069.018.096.025.193.021.29v2.253a10.55 10.55 0 0 1 1.7-1.911 2.621 2.621 0 0 1 1.7-.686 1.6 1.6 0 0 1 1.144.419c.543.542.586 1.407.1 2-.21.249-.52.39-.845.384a1.584 1.584 0 0 1-1.052-.479 1.485 1.485 0 0 0-.832-.484c-.32 0-.715.26-1.183.778a2.388 2.388 0 0 0-.7 1.6v6.2a2.052 2.052 0 0 0 .551 1.646c.54.353 1.187.506 1.828.431v.456h-6.769v-.458Z"
        data-name="\u30D1\u30B9 1051"
      />
      <path
        fill="#002076"
        d="M108.003 416.774c1.61-.9 3.299-1.651 5.046-2.242v-1.169a4.817 4.817 0 0 0-.277-1.951 1.96 1.96 0 0 0-1.939-.922c-.469 0-.929.124-1.334.359a1.135 1.135 0 0 0-.632 1.017c.009.187.032.373.069.556.034.165.057.332.069.5.043.425-.153.838-.509 1.074a1.287 1.287 0 0 1-.688.178 1.156 1.156 0 0 1-.949-.4 1.393 1.393 0 0 1-.329-.9 2.86 2.86 0 0 1 1.19-2.013 5.1 5.1 0 0 1 3.485-1.052c1.78 0 2.985.577 3.617 1.732.386.868.56 1.816.509 2.765v5.512c-.02.37.017.74.109 1.1.086.335.398.56.743.537.18.006.358-.029.523-.1.25-.136.49-.292.715-.466v.715a4.474 4.474 0 0 1-.949.88 2.766 2.766 0 0 1-1.566.523 1.527 1.527 0 0 1-1.355-.605 2.717 2.717 0 0 1-.461-1.443c-.555.5-1.153.95-1.786 1.346a4.675 4.675 0 0 1-2.379.743 2.857 2.857 0 0 1-2.048-.831 2.8 2.8 0 0 1-.866-2.112 3.917 3.917 0 0 1 1.994-3.326m5.046-1.554c-.912.285-1.788.672-2.612 1.154-1.32.817-1.98 1.742-1.98 2.777a2.112 2.112 0 0 0 .824 1.843c.348.254.769.389 1.2.385a3.293 3.293 0 0 0 1.739-.509c.495-.243.816-.74.832-1.292l-.003-4.358Z"
        data-name="\u30D1\u30B9 1052"
      />
      <path
        fill="#002076"
        d="M118.844 422.45c.494-.023.97-.193 1.367-.487a2.319 2.319 0 0 0 .392-1.628v-6.987a3.167 3.167 0 0 0-.165-1.225 1.082 1.082 0 0 0-1.072-.519c-.085 0-.17.004-.255.014-.086.009-.194.019-.322.039v-.48a134.64 134.64 0 0 0 2.666-.854l1.293-.428c.067 0 .105.023.116.069a.72.72 0 0 1 .021.194v2.02c.608-.6 1.27-1.143 1.976-1.623a4.128 4.128 0 0 1 2.224-.672 2.917 2.917 0 0 1 1.7.535c.987.699 1.481 1.94 1.482 3.726v6.423a2.1 2.1 0 0 0 .4 1.427c.357.331.838.496 1.323.454v.387h-5.873v-.387a2.349 2.349 0 0 0 1.4-.556 2.825 2.825 0 0 0 .4-1.821V414.2a3.956 3.956 0 0 0-.438-1.96 1.686 1.686 0 0 0-1.612-.774 2.99 2.99 0 0 0-1.64.547c-.429.314-.83.663-1.2 1.045v7.7c-.064.487.098.977.44 1.329.416.259.9.385 1.389.363v.387h-6.012v-.387Z"
        data-name="\u30D1\u30B9 1053"
      />
      <path
        fill="#002076"
        d="M141.102 410.285a4.4 4.4 0 0 1 1.059.8v-4.374a2.51 2.51 0 0 0-.185-1.156c-.125-.211-.421-.315-.887-.315-.111 0-.208.005-.289.012l-.551.056v-.454l1.128-.289c.412-.11.824-.225 1.237-.343s.775-.233 1.086-.345c.146-.046.391-.132.729-.26l.083.026-.028 1.445-.026 1.616c-.008.557-.014 1.1-.014 1.642l-.028 11.233c-.033.429.043.86.22 1.251.146.239.537.357 1.169.357.1 0 .2 0 .3-.007.1-.005.2-.016.3-.033v.454c-.055.017-.72.246-1.994.686l-2.158.811-.1-.123v-1.691a7.259 7.259 0 0 1-1.334 1.2 4.34 4.34 0 0 1-2.323.618 4.626 4.626 0 0 1-3.787-1.807 6.494 6.494 0 0 1-1.452-4.187 7.846 7.846 0 0 1 1.741-5.121 5.3 5.3 0 0 1 4.255-2.138 3.9 3.9 0 0 1 1.841.44m.4 10.7c.374-.294.612-.727.66-1.2v-5.9a3 3 0 0 0-.956-2.524 3.083 3.083 0 0 0-1.864-.736 3.016 3.016 0 0 0-2.694 1.533 6.978 6.978 0 0 0-.963 3.775 7.618 7.618 0 0 0 1.024 3.946 3.334 3.334 0 0 0 3.06 1.732 2.4 2.4 0 0 0 1.732-.632"
        data-name="\u30D1\u30B9 1054"
      />
      <path
        fill="#002076"
        d="M45.332 453.991a3.119 3.119 0 0 0 2.006-.635c.347-.687.486-1.46.4-2.224v-12.195a2.942 2.942 0 0 0-.447-1.939 3.208 3.208 0 0 0-1.959-.591v-.523h7.423a9.172 9.172 0 0 1 5.473 1.389 4.329 4.329 0 0 1 1.9 3.659 4.52 4.52 0 0 1-2.1 4.166 9.9 9.9 0 0 1-5.266 1.292c-.3 0-.688-.007-1.162-.021s-.824-.019-1.052-.019v5.114c0 1.058.163 1.725.489 2a4.263 4.263 0 0 0 2.179.531v.523h-7.884v-.527Zm9.5-16.539a7.586 7.586 0 0 0-3.025-.523 1.95 1.95 0 0 0-1.065.172 1.032 1.032 0 0 0-.2.762v7.34c.505.047.834.074.991.083.157.009.308.014.454.014a7 7 0 0 0 3.052-.551 3.793 3.793 0 0 0 2.022-3.752 3.532 3.532 0 0 0-2.228-3.548"
        data-name="\u30D1\u30B9 1055"
      />
      <path
        fill="#002076"
        d="M63.652 448.451c1.61-.9 3.299-1.651 5.046-2.242v-1.169a4.817 4.817 0 0 0-.275-1.952 1.961 1.961 0 0 0-1.939-.922c-.468 0-.929.124-1.334.359a1.135 1.135 0 0 0-.632 1.017c.009.187.032.373.069.556.034.165.057.332.069.5.043.425-.153.838-.509 1.074a1.287 1.287 0 0 1-.688.178 1.156 1.156 0 0 1-.949-.4 1.393 1.393 0 0 1-.329-.9 2.86 2.86 0 0 1 1.19-2.013 5.1 5.1 0 0 1 3.485-1.052c1.78 0 2.985.577 3.617 1.732.386.868.56 1.816.509 2.765v5.512c-.02.37.017.74.109 1.1.086.335.398.56.743.537.18.006.358-.029.523-.1.25-.136.49-.292.715-.466v.715a4.474 4.474 0 0 1-.949.88 2.766 2.766 0 0 1-1.566.523 1.527 1.527 0 0 1-1.355-.605 2.718 2.718 0 0 1-.461-1.443c-.555.5-1.153.95-1.786 1.346a4.675 4.675 0 0 1-2.379.743 2.857 2.857 0 0 1-2.048-.831 2.8 2.8 0 0 1-.866-2.112 3.917 3.917 0 0 1 1.994-3.326m5.046-1.554c-.911.285-1.788.672-2.612 1.154-1.32.817-1.98 1.742-1.98 2.777a2.112 2.112 0 0 0 .824 1.843c.348.254.769.389 1.2.385a3.293 3.293 0 0 0 1.739-.509c.495-.243.816-.74.832-1.292l-.003-4.358Z"
        data-name="\u30D1\u30B9 1056"
      />
      <path
        fill="#002076"
        d="M74.307 454.06a3.325 3.325 0 0 0 1.677-.447c.334-.399.484-.92.412-1.436v-6.2a5.268 5.268 0 0 0-.253-1.943.926.926 0 0 0-.927-.584 3.102 3.102 0 0 0-.364.032c-.151.023-.31.056-.475.09v-.454l1.6-.561c.546-.191.926-.33 1.139-.414a24.65 24.65 0 0 0 1.41-.591c.067 0 .1.021.118.069.018.096.025.193.021.29v2.253a10.55 10.55 0 0 1 1.7-1.911 2.621 2.621 0 0 1 1.7-.686 1.6 1.6 0 0 1 1.144.419c.543.542.586 1.407.1 2-.21.249-.52.39-.845.384a1.584 1.584 0 0 1-1.052-.479 1.485 1.485 0 0 0-.832-.484c-.32 0-.715.26-1.183.778a2.388 2.388 0 0 0-.7 1.6v6.2a2.052 2.052 0 0 0 .551 1.645c.54.353 1.187.506 1.828.431v.456h-6.769v-.457Z"
        data-name="\u30D1\u30B9 1057"
      />
      <path
        fill="#002076"
        d="M91.921 441.838v.989h-2.805l-.026 7.919a5.35 5.35 0 0 0 .178 1.582c.152.583.692.979 1.294.949a1.55 1.55 0 0 0 .859-.234c.307-.212.586-.462.831-.743l.359.3-.3.414a4.412 4.412 0 0 1-1.513 1.36 3.43 3.43 0 0 1-1.54.4 2.168 2.168 0 0 1-2.2-1.445 5.971 5.971 0 0 1-.315-2.172v-8.333h-1.5a.367.367 0 0 1-.1-.081.17.17 0 0 1-.035-.111.33.33 0 0 1 .062-.213 4.47 4.47 0 0 1 .392-.35c.488-.388.945-.813 1.367-1.272.28-.324.938-1.183 1.973-2.578a.434.434 0 0 1 .213.028c.023.019.035.088.035.206v3.382l2.771.003Z"
        data-name="\u30D1\u30B9 1058"
      />
      <path
        fill="#002076"
        d="M93.85 454.128a2.5 2.5 0 0 0 1.367-.487 2.319 2.319 0 0 0 .392-1.628v-6.987a3.167 3.167 0 0 0-.165-1.225 1.082 1.082 0 0 0-1.072-.519c-.085 0-.17.004-.255.014-.086.009-.194.019-.322.039v-.48a134.64 134.64 0 0 0 2.666-.854l1.293-.428c.067 0 .105.023.116.069a.716.716 0 0 1 .021.194v2.02c.608-.6 1.27-1.143 1.976-1.623a4.128 4.128 0 0 1 2.224-.672 2.917 2.917 0 0 1 1.7.535c.987.699 1.481 1.94 1.482 3.726v6.423a2.1 2.1 0 0 0 .4 1.427c.357.331.838.496 1.323.454v.387h-5.873v-.387a2.348 2.348 0 0 0 1.4-.556 2.826 2.826 0 0 0 .4-1.821v-5.871a3.956 3.956 0 0 0-.438-1.96 1.686 1.686 0 0 0-1.612-.774 2.99 2.99 0 0 0-1.64.547c-.429.314-.83.663-1.2 1.045v7.7c-.064.487.098.977.44 1.329.416.259.9.385 1.389.363v.387H93.85v-.387Z"
        data-name="\u30D1\u30B9 1059"
      />
      <path
        fill="#002076"
        d="M117.501 442.958a4.9 4.9 0 0 1 1.457 3.8h-8.731a7.3 7.3 0 0 0 1.445 4.635 4.031 4.031 0 0 0 3.094 1.455 3.883 3.883 0 0 0 2.432-.755 7.575 7.575 0 0 0 1.828-2.138l.482.164a7.856 7.856 0 0 1-2.11 3.238 5.268 5.268 0 0 1-3.789 1.484 4.634 4.634 0 0 1-3.993-1.95 7.481 7.481 0 0 1-1.41-4.5 7.316 7.316 0 0 1 1.637-4.771 5.242 5.242 0 0 1 4.261-2.006 4.858 4.858 0 0 1 3.4 1.341m-6.585 1.051a5.456 5.456 0 0 0-.632 1.871h5.8a5.75 5.75 0 0 0-.523-2.048 2.372 2.372 0 0 0-2.256-1.209 2.629 2.629 0 0 0-2.392 1.387"
        data-name="\u30D1\u30B9 1060"
      />
      <path
        fill="#002076"
        d="M120.614 454.06a3.325 3.325 0 0 0 1.677-.447c.334-.399.484-.92.412-1.436v-6.2a5.268 5.268 0 0 0-.253-1.943.926.926 0 0 0-.927-.584 3.102 3.102 0 0 0-.364.032c-.151.023-.31.056-.475.09v-.454l1.6-.561c.546-.191.926-.33 1.139-.414a24.65 24.65 0 0 0 1.41-.591c.067 0 .1.021.118.069.018.096.025.193.021.29v2.253a10.55 10.55 0 0 1 1.7-1.911 2.621 2.621 0 0 1 1.7-.686 1.6 1.6 0 0 1 1.144.419c.543.542.586 1.407.1 2-.21.249-.52.39-.845.384a1.584 1.584 0 0 1-1.052-.479 1.485 1.485 0 0 0-.832-.484c-.32 0-.715.26-1.183.778a2.388 2.388 0 0 0-.7 1.6v6.2a2.052 2.052 0 0 0 .551 1.645c.54.353 1.187.506 1.828.431v.456h-6.769v-.457Z"
        data-name="\u30D1\u30B9 1061"
      />
      <path
        fill="#002076"
        d="M131.899 450.183h.454c.137.847.425 1.661.85 2.406a3.112 3.112 0 0 0 2.812 1.54 2.259 2.259 0 0 0 1.626-.57c.4-.385.619-.922.6-1.478a2.039 2.039 0 0 0-.345-1.114 3.507 3.507 0 0 0-1.209-1.044l-1.54-.88a8.807 8.807 0 0 1-2.488-1.829 3.216 3.216 0 0 1-.8-2.173 3.249 3.249 0 0 1 1.1-2.529 3.977 3.977 0 0 1 2.763-.991c.544.01 1.084.103 1.6.275.321.115.65.207.984.276a.705.705 0 0 0 .357-.069.584.584 0 0 0 .192-.22h.331l.1 3.835h-.426a6.348 6.348 0 0 0-.743-2.075 2.729 2.729 0 0 0-2.46-1.376 1.952 1.952 0 0 0-2.063 1.98c0 .845.633 1.597 1.9 2.256l1.814.975c1.953 1.067 2.93 2.304 2.928 3.713a3.32 3.32 0 0 1-1.214 2.652 4.738 4.738 0 0 1-3.178 1.031 7.342 7.342 0 0 1-1.865-.275 8.468 8.468 0 0 0-1.234-.275.411.411 0 0 0-.29.116.833.833 0 0 0-.192.282h-.357l-.007-4.439Z"
        data-name="\u30D1\u30B9 1062"
      />
      <path
        fill="#002076"
        d="M141.695 454.129a2.424 2.424 0 0 0 1.478-.556c.305-.59.427-1.257.35-1.916V438.46a2.48 2.48 0 0 0-.2-1.137c-.132-.239-.456-.364-.97-.364-.1 0-.2.007-.3.023l-.324.046v-.495l1.492-.431c.591-.17 1.001-.292 1.23-.364l1.32-.414.028.1v8.436a8.907 8.907 0 0 1 1.54-1.455 4.542 4.542 0 0 1 2.612-.827 3.018 3.018 0 0 1 3.039 1.872c.324.827.474 1.712.44 2.6v5.61a3.5 3.5 0 0 0 .336 1.9c.371.36.865.565 1.382.575v.387h-5.945v-.387a2.563 2.563 0 0 0 1.54-.561c.3-.59.42-1.254.343-1.911v-5.568a4.54 4.54 0 0 0-.447-2.161 1.74 1.74 0 0 0-1.684-.824 3.362 3.362 0 0 0-2.077.771c-.669.512-1 .845-1 1v6.779a3.228 3.228 0 0 0 .357 1.924c.44.337.973.529 1.526.549v.387h-6.066v-.391Z"
        data-name="\u30D1\u30B9 1063"
      />
      <path
        fill="#002076"
        d="M156.934 454.129a3.494 3.494 0 0 0 1.857-.5c.255-.243.383-.9.384-1.973v-6.559a4.17 4.17 0 0 0-.123-1.237.829.829 0 0 0-.866-.563c-.1 0-.201.008-.3.028-.1.018-.373.09-.832.22v-.428l.591-.192c1.6-.523 2.718-.917 3.354-1.183.158-.079.326-.134.5-.165a.79.79 0 0 1 .026.208v9.871a3.213 3.213 0 0 0 .378 1.966c.5.35 1.101.53 1.712.509v.385h-6.681v-.387Zm2.186-18.383a1.36 1.36 0 0 1 1.017-.424c.79 0 1.43.64 1.431 1.429v.007c.005.377-.148.74-.421 1a1.425 1.425 0 0 1-2.439-1 1.4 1.4 0 0 1 .412-1.012"
        data-name="\u30D1\u30B9 1064"
      />
      <path
        fill="#002076"
        d="M164.297 460.192a2.214 2.214 0 0 0 1.631-.555c.267-.442.388-.956.348-1.471v-13.2a2.28 2.28 0 0 0-.254-1.329 1.218 1.218 0 0 0-.956-.3c-.1 0-.194.005-.282.014s-.213.026-.378.055v-.454l1.308-.426c.046-.009.967-.325 2.763-.949.042 0 .08.024.1.062a.312.312 0 0 1 .033.144v1.854a10.004 10.004 0 0 1 1.594-1.348 4.755 4.755 0 0 1 2.545-.769 4.148 4.148 0 0 1 3.265 1.614 6.6 6.6 0 0 1 1.367 4.414 8.226 8.226 0 0 1-1.642 4.989 4.945 4.945 0 0 1-4.132 2.24 4.087 4.087 0 0 1-1.334-.194 3.959 3.959 0 0 1-1.621-1.058v4.512c0 .927.15 1.494.452 1.705a4.57 4.57 0 0 0 1.98.426v.482h-6.787v-.458Zm5.424-6.826c.499.315 1.08.475 1.67.461a3.063 3.063 0 0 0 2.538-1.477 7 7 0 0 0 1.051-4.113 5.61 5.61 0 0 0-1.093-3.777 3.288 3.288 0 0 0-2.536-1.25 3.03 3.03 0 0 0-1.857.627 1.67 1.67 0 0 0-.838 1.227v6.907a2.759 2.759 0 0 0 1.065 1.394"
        data-name="\u30D1\u30B9 1065"
      />
      <path
        fill="#002076"
        d="M106.366 347.933a28.887 28.887 0 0 1 3.033 13.473 33.658 33.658 0 0 1-1.865 11.277c-2.344 6.638-6.404 9.957-12.18 9.956-3.95 0-7.199-1.794-9.744-5.383-2.728-3.83-4.092-9.032-4.092-15.608a30.1 30.1 0 0 1 2.742-13.35c2.467-5.074 6.256-7.61 11.367-7.61 4.613 0 8.192 2.416 10.739 7.249m-4.572 28.154c1.102-3.226 1.653-7.893 1.653-14a54.636 54.636 0 0 0-.991-11.661c-1.244-5.408-3.57-8.112-6.978-8.113-3.408 0-5.743 2.784-7.006 8.354a55.634 55.634 0 0 0-.994 11.721c-.088 3.66.254 7.32 1.021 10.9 1.283 5.09 3.689 7.633 7.219 7.632 2.948 0 4.973-1.613 6.075-4.838"
        data-name="\u30D1\u30B9 1066"
      />
      <path
        fill="#002076"
        d="M120.445 378.503c1.859 1.293 3.29 1.94 4.292 1.941a7.751 7.751 0 0 0 6.054-3.053 11.2 11.2 0 0 0 2.592-7.5c0-5.193-2.771-8.933-8.314-11.219a22.71 22.71 0 0 0-8.585-1.9 4.282 4.282 0 0 1-.829-.044.975.975 0 0 1-.4-.315l.1-.4c.024-.1.067-.215.107-.317l6.555-14.38h12.9a2.785 2.785 0 0 0 1.487-.331c.503-.355.962-.77 1.367-1.234l.48.421-2.316 5.565c-.079.18-.3.3-.662.345-.39.053-.781.078-1.174.076h-11.651l-2.559 5.264a54.9 54.9 0 0 1 7.228 1.593 16.259 16.259 0 0 1 6.293 3.729c5.854 5.842 5.864 15.323.022 21.176-.12.122-.244.24-.369.358a16.831 16.831 0 0 1-12 4.451 16.172 16.172 0 0 1-4.759-.572c-2.006-.62-3.009-1.732-3.009-3.337a2.217 2.217 0 0 1 .646-1.58 2.659 2.659 0 0 1 2-.676c1.146 0 2.646.646 4.5 1.939"
        data-name="\u30D1\u30B9 1067"
      />
    </g>
  </svg>
);
export default SvgGraph;
