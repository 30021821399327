import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgGraph from '../generated/svg/graph'
import { useMenuQueryQuery } from '../generated/graphql'

const Outer = styled.div`
    margin: 2rem;
    scroll-margin-top: 14rem;
    @media (max-width: 40rem){
      margin: 0;
      scroll-margin-top: 9rem;
    }
`

const SectionName = styled.div`
    font-size: 3.25rem;
    font-family: 'Lusitana', serif;
    text-align: center;
    margin: 10rem 0 1.5rem;
    @media (max-width: 40rem){
      margin-top: 5rem;
    }
`

const Comment = styled.div`
    font-size: 1.25rem;
    color: #707070;
    text-align: center;
    margin-bottom: 6rem;
    @media (max-width: 40rem){
      font-size: 1.125rem;
      font-family: 'Lusitana', serif;
      line-height: 2.2rem;
    }
`

const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 4rem auto;
    max-width: 68rem;
    @media (max-width: 59.375rem){
      display: block;
      margin: 0 auto;
    }
    @media (max-width: 40rem){
      max-width: 20rem; 
      margin: 0 auto;
    }
`

const Box1 = styled.div`
    flex: 1 1 40%;
    padding: 2rem;
    &:nth-child(n+3){
        flex: 1 1 10%;
    }
    @media (max-width: 40rem){
      font-family: 'Lusitana', serif;
      margin: 0 auto;
      padding: 2rem 0;
    }
`

const BoxTitle1 = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 0.125rem solid #244F89;
`

const Number1 = styled.div`
    font-size: 3.375rem;
    font-family: 'Lusitana', serif;
    color: #244F89;
    padding: 0.7rem 0.7rem 0.7rem 0;
`

const TitleOuter1 = styled.div`
    padding: 0.3rem 1rem;
    border-left: 0.08rem solid #244F89;
`

const Title1 = styled.div`
    font-size: 1.4rem;
    font-family: 'Lusitana', serif;
    color: #244F89;
    margin-bottom: 0.5rem;
`

const TitleJa1 = styled.div`
    font-size: 0.9375rem;
    color: #707070;
`

const Detail1 = styled.div`
    font-size: 0.8125rem;
    color: #707070;
    margin-top: 1rem;
    line-height: 1.7rem;
    @media (max-width: 40rem){
      font-size: 0.875rem;
    }
`

const TextBox1 = styled.div`
    font-size: 0.875rem;
    color: #707070;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1rem;
    margin-top: 2rem;
    @media (max-width: 40rem){
      grid-template-columns: repeat(1,1fr);
      align-items: center;
      justify-content: center;
    }
`

const Text1 = styled.div`
    border-radius: 3.125rem;
    border: 0.08rem solid #707070; 
    text-align: center;
    height: 3.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 40rem){
      width: 14.25rem;
      margin-left: auto;
      margin-right: auto;
    }
`


const PrBox = styled.div`
    @media (max-width: 43.75rem){
      font-family: 'Lusitana', serif;
      max-width: 20rem;
      margin: 0 auto;
    }
`

const PrText1 = styled.div`
    color: #707070;
    font-size: 1.0625rem;
    text-align: center;
    @media (max-width: 43.75rem){
      font-size: 1.4375rem;
      margin-top: 5rem;
      line-height: 2rem;
    }
`

const PrText2 = styled.div`
    color: #244F89;
    font-size: 2.5rem;
    text-align: center;
    margin: 1rem 0 2rem;
    @media (max-width: 43.75rem){
      font-size: 2rem;
      margin: 2rem 0;
    }
`

const PrText3 = styled.div`
    color: #707070;
    font-size: 1.25rem;
    text-align: center;
    line-height: 2.5rem;
    @media (max-width: 43.75rem){
      font-size: 1rem;
      line-height: 2rem;
    }
`

const PrGraph = styled.div`
    svg {
        max-width: 44.6875rem;
        height: auto;
        display: block;
        margin: 2rem auto;
        @media (max-width: 43.75rem){
          max-width: 22.875rem;
          height: auto;
        }
    }
`

const PrText4 = styled.div`
    color: #707070;
    font-size: 0.9375rem;
    text-align: center;
    line-height: 2.3rem;
    margin-bottom: 3rem;
    @media (max-width: 43.75rem){
      font-size: 0.9rem;
      line-height: 1.6rem;
    }
`

const DownloadButton = styled.a`
    display: block;
    color: #244F89;
    font-size: 1.125rem;
    text-align: center;
    margin: 0 auto 4rem;
    border-radius: 3.125rem;
    border: 0.05rem solid #244F89;
    width: 13.8125rem;
    padding: 0.3rem;
    &:hover {
    color: white;
    background-color: #244F89;
  }
    transition: all 0.4s;
`
const Mbr = styled.br`
    display: none;
    @media (max-width: 40rem){
      display: inline;
    }
`
const Dbr = styled.br`
    @media (max-width: 43.75rem){
      display: none;
    }
`

type ServiceSectionProps = {

}
export const ServiceSection: FunctionComponent<ServiceSectionProps> = () => {
  const menuQuery = useMenuQueryQuery()
    
  return <Outer id='service-section'>
    <SectionName>Service</SectionName>
    <Comment>あらゆるPR手法から最適なプランを<Mbr/>構築・ご提案いたします。</Comment>
    <FlexBox>
        <Box1>
            <BoxTitle1>
                <Number1>01</Number1>
                <TitleOuter1>
                    <Title1>Media Relations</Title1>
                    <TitleJa1>メディアリレーション</TitleJa1>
                </TitleOuter1>
            </BoxTitle1>
            <Detail1>メディア（雑誌・WEB)へのブランド・製品PR</Detail1>
            <TextBox1>
                <Text1>プレスリリース</Text1>
                <Text1>製品プレス発表会</Text1>
                <Text1>プレスコンタクト</Text1>
            </TextBox1>
        </Box1>
        <Box1>
            <BoxTitle1>
                <Number1>02</Number1>
                <TitleOuter1>
                    <Title1>Degital/SNS</Title1>
                    <TitleJa1>デジタルコミュニケーション</TitleJa1>
                </TitleOuter1>
            </BoxTitle1>
            <Detail1>ソーシャルメディアに対するブランド・製品PR</Detail1>
            <TextBox1>
                <Text1>インフルエンサーへの<br/>コンタクト</Text1>
                <Text1>SNS構築</Text1>
            </TextBox1>
        </Box1>
        <Box1>
            <BoxTitle1>
                <Number1>03</Number1>
                <TitleOuter1>
                    <Title1>Customer Relations</Title1>
                    <TitleJa1>カスタマーリレーション</TitleJa1>
                </TitleOuter1>
            </BoxTitle1>
            <Detail1>ブランド・製品へのコアなファンを増やすコミュニケーション</Detail1>
        </Box1>
        <Box1>
            <BoxTitle1>
                <Number1>04</Number1>
                <TitleOuter1>
                    <Title1>Brand Development</Title1>
                    <TitleJa1>ブランド開発</TitleJa1>
                </TitleOuter1>
            </BoxTitle1>
            <Detail1>リブランディングおよびスタートアップにおけるコンセプト・メッセージ・製品開発のサポート</Detail1>
        </Box1>
        <Box1>
            <BoxTitle1>
                <Number1>05</Number1>
                <TitleOuter1>
                    <Title1>Brand Partnership</Title1>
                    <TitleJa1>パートナーシップ</TitleJa1>
                </TitleOuter1>
            </BoxTitle1>
            <Detail1>ブランド価値を共有できる人材や企業とのコラボレーションコーディネート</Detail1>
        </Box1>
    </FlexBox>
    <PrBox>
        <PrText1>360°視点の<Mbr/>PRコミュニケーション戦略</PrText1>
        <PrText2>360°PRストラテジー</PrText2>
        <PrText3>ブランドが目指すゴールや<Mbr/>課題に真摯に向き合い、<br/>あらゆる角度から戦略を描き実現します。</PrText3>
        <PrGraph>
            <SvgGraph/>
        </PrGraph>
        <PrText4>「360°PRストラテジー」は、メディアリレーション、製品の開発・プロモーショ ン支援、<Dbr/>およびブランドのライフサイクル全体をカバーするブランド全体に対する PRアプローチです。</PrText4>
        <DownloadButton href={menuQuery.data?.menu?.menuFields?.file?.mediaItemUrl ?? ''} target="_blank">MENU DOWNLOAD</DownloadButton>
    </PrBox>
  </Outer>
}