import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const Outer = styled.div``

const FooterText = styled.div`
    color: #244F89;
    font-size: 0.8125rem;
    text-align: center;
    font-family: 'Lusitana', serif;
    width: 100%;
    margin: 3rem 0;
    @media (max-width: 40rem){
      font-size: 0.6rem;
    }
`

type FooterProps = {

}
export const Footer: FunctionComponent<FooterProps> = () => {
  return <Outer>
<FooterText>Copyright 2022 KURITA PR OFFICE.  All Rights Reserved.</FooterText>
  </Outer>
}