import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '../components/image'

const Outer = styled.div`
    background-color: #FDF7F3;
    scroll-margin-top: 7rem;
    @media (max-width: 40rem){
      scroll-margin-top: 2rem; 
    }
`

const SectionName = styled.div`
    font-family: 'Lusitana', serif;
    font-size: 3.25rem;
    padding: 7rem 0 0;
    text-align: center;
`
const TextOuter = styled.div`
    padding: 5rem 1rem 15rem;
    max-width: 35rem;
    height: auto;
    margin: 0 auto;
    @media (max-width: 40rem){
      max-width: 20rem;
      font-family: 'Lusitana', serif;
      padding: 3rem 0 7rem;
    }
`


const Text1 = styled.div`
    color: #000;
    font-size: 2rem;
    margin: 1.5rem 0;
    line-height: 1.5;
    white-space: nowrap;
    @media (max-width: 40rem){
      font-size: min(4.8vw,1.3rem);
    }
`

const Text2 = styled.div`
    font-size: 0.95rem;
    color: #707070;
    margin-bottom: 1.5rem;
    line-height: 1.68rem;
    @media (max-width: 40rem){
      font-size: 1.125rem;
      margin: 2rem 0 4rem;
      line-height: 2rem;
    }
`

const Text3 = styled.div`
    font-size: 0.6875rem;
    color: #707070;
    margin-bottom: 0.5rem;
    @media (max-width: 40rem){
      font-size: 0.9375rem;
      margin-bottom: 1rem;
    }
`

const NameOuter = styled.div`
    color: #244F89;
    display: flex;
    margin-bottom: 1.5rem;
    @media (max-width: 40rem){
      flex-flow: column;
      gap: 0.8rem;
      font-size: 1.625rem;
      margin-bottom: 1rem;
    }
`

const NameJa = styled.div`
    &::after {
        content: "/";
        margin: 0 0.3rem;
        @media (max-width: 40rem){
          display: none;
        }
    }
`

const NameEn = styled.div``

const Text4 = styled.div`
    color: #707070;
    font-size: 0.75rem;
    line-height: 1.5rem;
    @media (max-width: 40rem){
      font-size: 1.0625rem;
      line-height: 2rem;
    }
`

type MessageSectionProps = {

}
export const MessageSection: FunctionComponent<MessageSectionProps> = () => {
  return <Outer id='message-section'>
    <SectionName>Message</SectionName>
    <TextOuter>
        <Text1>私たちは、ウェルネスを軸とした<br/>PRコミュニケーションオフィスです。</Text1>
        <Text2>従来のPRの領域を超え、360°視点でブランディングに関わるあらゆる方向から企業・ブランドをサポートします。<br/>
        理念・価値・強みを洗い出し、PRコミュニケーション戦略の構築および実施、企業・ブランドがThought Leader(ソートリーダー)となることを目指します。</Text2>
        <Text3>PRディレクター</Text3>
        <NameOuter>
            <NameJa>栗田 綾野</NameJa>
            <NameEn>KURITA AYANO</NameEn>
        </NameOuter>
        <Text4>上智大学卒業後、化粧品企業 PR/マーケティング部門を経て独立。ウェルネスを軸とした
        国内外のビューティ・フード・ライフスタイルなど幅広い分野のPR戦略を手掛ける。</Text4>
    </TextOuter>
  </Outer>
}