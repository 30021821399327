import * as React from "react";
import type { SVGProps } from "react";
const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="\u30EC\u30A4\u30E4\u30FC 1"
    viewBox="0 0 20.627 20.627"
    {...props}
  >
    <g
      fill="none"
      stroke="currentcolor"
      strokeLinecap="round"
      strokeWidth={2}
      data-name="\u30B0\u30EB\u30FC\u30D7 358"
    >
      <path d="M19.627 1 1 19.628" data-name="\u30D1\u30B9 589" />
      <path d="m1 1 18.627 18.627" data-name="\u30D1\u30B9 590" />
    </g>
  </svg>
);
export default SvgDelete;
